import React from "react";

import { Table } from "reactstrap";
import { EmptyRecord } from "../../../utilities/EmptyRecord";
import { ProgressLoader } from "../../../components/progressloader/ProgressLoader";
import { appHelpers } from "../../../_helpers";

const FlightsScheduleTable = ({
  flightsScheduleData,
  isRequestingSchedule
}) => {
  return (
    <div className="container">

      {/* fetching schedules */}
      {isRequestingSchedule && (
        <ProgressLoader message="Loading flight schedules..." />
      )}

      {/* when no record */}
      {!isRequestingSchedule && flightsScheduleData.length === 0 && (
        <EmptyRecord message="No flight schedule record found." />
      )}

      {/* when there are records */}
      {!isRequestingSchedule && flightsScheduleData.length > 0 && (
        <Table className="table table-scroll table-striped hover">
          <thead>
            <tr>
              {/* <th className="col-xs-1" scope="col">#</th> */}
              <th scope="col">Airline</th>
              <th scope="col">FlightNumber</th>
              <th scope="col">Departure Airport</th>
              <th scope="col">Destination Airport</th>
            </tr>
          </thead>
          <tbody>
            {flightsScheduleData.map((item, index) => {
              return (
                <tr className="v-middle" key={index}>
                  {/* <td className="col-xs-1">{index + 1}</td> */}
                  <td><img className="img img-responsive" height="50px" src={`/images/airlines/${appHelpers.interpretAirline(item.AirlineId)}`} alt="airline"/></td>
                  <td>{item.FlightNumber}</td>
                  <td>{appHelpers.interpretAirport(item.DepartureAirportId)}</td>
                  <td>{appHelpers.interpretAirport(item.DestinationAirportId)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default FlightsScheduleTable;
