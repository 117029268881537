import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import Naira from "./../../assets/images/icons/naira-white2.png";
import Dollar from "./../../assets/images/icons/dollar-white.png";
// import  Budget from "./../../assets/images/icons/budget.png"
import Calculator from "./../../assets/images/icons/calculator.png";

export const CurrencySwitch = ({ setButtonSelected, buttonSelected }) => {
  return (
    <div className={"toggle-btn"}>
      <ButtonGroup>
        <Button
          className={"btn-rounded"}
          color={"primary"}
          onClick={() => setButtonSelected(1)}
          active={buttonSelected === 1}
        >
          <img src={Calculator} alt={"Total Icon"}/> Total
        </Button>
        <Button
          // className={"btn-currency"}
            color={"primary"}
          onClick={() => setButtonSelected(2)}
          active={buttonSelected === 2}
        >
          <img src={Naira} alt={"Naira Icon"} /> Naira
        </Button>
        <Button
          className={"btn-rounded"}
          color={"primary"}
          onClick={() => setButtonSelected(3)}
          active={buttonSelected === 3}
        >
          <img src={Dollar} alt={"Dollar Icon"} /> Dollar
        </Button>
      </ButtonGroup>
    </div>
  );
};

export const AgencySwitch = ({
  setAgencyButtonSelected,
  selectedAgencyButton
}) => {

  return (
    <div className={"agency-toggle-btn"}>
      <Button
        className={"btn-rounded"}
        style={{ borderRadius: "50px 0 0px 50px" }}
        color="primary"
        onClick={() => setAgencyButtonSelected(1)}
        active={selectedAgencyButton === 1}
      >
        FAAN
      </Button>
      <Button
        className={"btn-rounded"}
        style={{ borderRadius: "0 50px 50px 0px" }}
        color="primary"
        onClick={() => setAgencyButtonSelected(2)}
        active={selectedAgencyButton === 2}
      >
        NCAA
      </Button>
    </div>
  );
};
