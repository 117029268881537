import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";
import moment from "moment";

export const commonService = {
  fetchAllAirlines,
  searchInvoiceSummaryMetrics,
  searchMonthlyRevenueTrend,
  searchDailyRevenueTrend,
  searchTopLocations,
  searchTopCustomers,
  fetchDailyPassengersList,
  fetchPassengerPerAirline,
  fetchPassengerPerMonth,
  fetchPassengerPerAirport,
  fetchFlightsData,
  fetchFlightsSchedule,
  fetchAllAirports,
  login,
  logOut,
  fetchTotalPassengerCount,
  fetchCoordinatesDetails,
  fetchIntlPassengerPerAirline,
  fetchIntlPassengerPerAirport,
  fetchIntlPassengerAirportTraffic
};

function login(payload) {
  return appHelpers
    .postRequest(`${appConstants.PAS_URL}/api/Account/Login`, payload)
    .then(res => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch(error => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function logOut() {
  appHelpers.destroySession();
}

function fetchAllAirlines() {
  // return appHelpers.getRequest(`${appConstants.PAS_URL}/api/airline/search?IsLocal=${true}`, authHeader())
  return appHelpers
    .getRequest(`${appConstants.PAS_URL}/api/Airline/GetAllPscAirline`)
    .then(res => {
      return res;
    })
    .catch(error => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchInvoiceSummaryMetrics(payload) {
  return (
    appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/open/SearchInvoiceSummaryMetrics`,
        payload
      )
      // return appHelpers
      //   .postRequest(
      //     `http://bdaservice.test.vggdev.com/api/open/SearchInvoiceSummaryMetrics`,
      //     payload
      //   )
      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function searchMonthlyRevenueTrend(payload) {
  return (
    appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/open/SearchMonthlyRevenueTrend`,
        payload
      )
      // return appHelpers
      //   .postRequest(
      //     `http://bdaservice.test.vggdev.com/api/open/SearchMonthlyRevenueTrend`,
      //     payload
      //   )
      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function searchDailyRevenueTrend(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/open/SearchDailyRevenueTrend`,
      payload
    )
    .then(res => {
      return res;
    })
    .catch(error => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchTopLocations(payload) {
  return (
    appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/open/SearchTopLocations`,
        payload
      )
      // return appHelpers
      //   .postRequest(
      //     `http://bdaservice.test.vggdev.com/api/open/SearchTopLocations`,
      //     payload
      //   )
      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function searchTopCustomers(payload) {
  return (
    appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/open/SearchTopCustomers`,
        payload
      )
      // return appHelpers
      //   .postRequest(
      //     `http://bdaservice.test.vggdev.com/api/open/SearchTopCustomers`,
      //     payload
      //   )
      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function fetchDailyPassengersList(payload) {
  return (
    appHelpers
      .getRequest(
        `${appConstants.PAS_URL}/api/IntelligenceDashboard/DailyPassengerList?from=${payload.startDate}&to=${payload.endDate}`
      )
      // return appHelpers
      //   .getRequest(
      //     `http://psc-api.test.vggdev.com/api/IntelligenceDashboard/DailyPassengerList?from=${payload.startDate}&to=${payload.endDate}`,
      //     payload
      //   )
      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function fetchPassengerPerAirline(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/IntelligenceDashboard/PassengerPerAirline?date=${payload.date}&pageSize=${payload.pageSize}&isLocal=${payload.isLocal}`
    )
    .then(res => {
      return res;
    })
    .catch(error => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function fetchPassengerPerMonth(payload) {
  return (
    appHelpers
      .getRequest(
        `${appConstants.PAS_URL}/api/IntelligenceDashboard/PassengerPerMonth?date=${payload.date}&isLocal=${payload.isLocal}`
      )
      // return appHelpers
      //   .getRequest(
      //     `http://psc-api.test.vggdev.com/api/IntelligenceDashboard/PassengerPerMonth?date=${payload.date}`,
      //     payload
      //   )
      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function fetchPassengerPerAirport(payload) {
  return (
    appHelpers
      .getRequest(
        `${appConstants.PAS_URL}/api/IntelligenceDashboard/PassengerPerAirport?date=${payload.date}&pageSize=${payload.pageSize}&isLocal=${payload.isLocal}`
      )
      // return appHelpers
      //   .getRequest(
      //     `http://psc-api.test.vggdev.com/api/IntelligenceDashboard/PassengerPerAirport?date=${payload.date}`,
      //     payload
      //   )
      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function fetchIntlPassengerPerAirport(payload) {
  // return (
  //     appHelpers
  //         .getRequest(
  //             `${appConstants.PAS_URL}/api/IntelligenceDashboard/PassengerPerAirport?date=${payload.date}&pageSize=${payload.pageSize}&isLocal=${payload.isLocal}`
  //         )
          return appHelpers
            .getRequest(
              `${appConstants.PAS_URL}/api/IntelligenceDashboard/PassengerPerAirportByDateRange?from=${payload.from}&to=${payload.to}&isLocal=${payload.isLocal}&pageSize=${payload.pageSize}&page=${payload.page}`

            )
          .then(res => {
            return res;
          })
          .catch(error => {
            let errorMessage = appHelpers.interpretErrorResponse(error);
            return appHelpers.formatPromiseResponse(
                errorMessage,
                appConstants.ERROR_RESPONSE
            );
          })
}

function fetchIntlPassengerAirportTraffic(payload) {
  return (
      appHelpers
          .getRequest(
              `${appConstants.PAS_URL}/api/intelligencedashboard/AirportTraffic?from=${payload.from}&to=${payload.to}&pageSize=${payload.pageSize}&isLocal=${payload.isLocal}`
          )
          // return appHelpers
          //   .getRequest(
          //     `http://psc-api.test.vggdev.com/api/IntelligenceDashboard/PassengerPerAirport?date=${payload.date}`,
          //     payload
          //   )
          .then(res => {
            return res;
          })
          .catch(error => {
            let errorMessage = appHelpers.interpretErrorResponse(error);
            return appHelpers.formatPromiseResponse(
                errorMessage,
                appConstants.ERROR_RESPONSE
            );
          })
  );
}
function fetchIntlPassengerPerAirline(payload) {
  // return (
  //     appHelpers
  //         .getRequest(
  //             `${appConstants.PAS_URL}/api/IntelligenceDashboard/PassengerPerAirport?date=${payload.date}&pageSize=${payload.pageSize}&isLocal=${payload.isLocal}`
  //         )
  return appHelpers
      .getRequest(
          `${appConstants.PAS_URL}/api/IntelligenceDashboard/PassengerPerAirlineByDateRange?from=${payload.from}&to=${payload.to}&isLocal=${payload.isLocal}&pageSize=${payload.pageSize}&page=${payload.page}`

      )
      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
        );
      })
}

function fetchFlightsData() {
  let dateToday = moment().format("YYYY-MM-DD");
  return (
  
    appHelpers
      .getRequest(
        `${
          appConstants.PAS_URL
        }/api/Charter/GetDashboardFlight?startdate=${dateToday}&enddate=${dateToday}`
      )
    // appHelpers
    //   .getRequest(
    //     `http://charter-api.test.vggdev.com/api/Charter/GetDashboardFlight?startdate=2019-09-02&enddate=2019-11-04`
    //   )

      .then(res => {
        return res;
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function fetchFlightsSchedule(dayId) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Flight/Search?isLocal=true&airlineId=&flightId=&flightNumber=&departureAirportId=&destinationAirportId=&dayId=${dayId}&isActive=true`
    )
    .then(res => {
      return res;
    })
    .catch(error => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function fetchAllAirports() {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/airport/search?countryCode=NG&isLocal=`
    )

    .then(res => {
      return res;
    })
    .catch(error => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

// data is from Avicollect
function fetchTotalPassengerCount(payload) {
  // return appHelpers.postRequest(`http://fmd.test.vggdev.com/api/manifest/SearchIntelligenceDashboardSummaryMetrics`, payload)

  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/manifest/SearchFmdIntelligenceDashboardSummaryMetrics`,
      payload
    )

    .then(res => {
      return res;
    })
    .catch(error => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

async function fetchCoordinatesDetails(payload) {
  try {
    const startResponse = await appHelpers.getRequest(
      ` https://nominatim.openstreetmap.org/reverse?format=json&lat=${payload.startLatitude}&lon=${payload.startLongitude}`
    );
    const endResponse = await appHelpers.getRequest(
      ` https://nominatim.openstreetmap.org/reverse?format=json&lat=${payload.endLatitude}&lon=${payload.endLongitude}`
    );

    return {startResponse, endResponse}
  } catch (error) {
    let errorMessage = appHelpers.interpretErrorResponse(error);
    return appHelpers.formatPromiseResponse(
      errorMessage,
      appConstants.ERROR_RESPONSE
    );
  }
}
