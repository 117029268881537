import React from "react";
import { Card, CardBody, Row, Col, Tooltip } from "reactstrap";
import Chart from "react-apexcharts";
import { BarChartOptions } from "../../Charts";
import "./index.css";
import moment from "moment";
import { connect } from "react-redux";
import { alertActions, commonActions } from "../../_actions";
import { appHelpers } from "../../_helpers";
import { graphHelpers } from "../../_helpers/graph.helpers";
import { commonService } from "../../_services";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_region_nigeriaLow from "@amcharts/amcharts4-geodata/nigeriaLow";
import { Nigeria } from "nigerianstates-map";
import { PassengersCard } from "./passengers-card";
import BottomCard from "../../components/bottomcard/BottomCard";
import PassengerTrafficTable from "./passengersTable";
import { store } from "react-notifications-component";
import _ from "lodash";

import AirportMarker from "../../assets/images/airport-marker.png";
import {Toggle} from "../../components/Toggle";


am4core.useTheme(am4themes_animated);

class Passengers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passengerStartDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"), //For Production
      // passengerStartDate: moment()
      //     .month(9)
      //     .startOf("month"),
      passengersEndDate: moment(),
      passengersTrafficDate: moment().subtract(1, "days"), //For Production
      // passengersTrafficDate: moment()
      //     .month(7)
      //     .endOf("month"),
      passengersStatisticsDate: moment(), //For Production
      // passengersStatisticsDate: moment()
      //     .month(4)
      //     .endOf("month"),
      passengersPerAirportDate: moment().subtract(1, "days"), //For Production
      // passengersPerAirportDate: moment()
      //     .month(7)
      //     .endOf("month"),
      passengersToday: 0,
      passengersYesterday: 0,
      passengersThisMonth: 0,
      passengersYearToDate: 0,
      passengersYearToDateIntl: 0,
      passengersTrafficData: [],
      passengersStatisticsData: [],
      passengersStatisticsCategory: [],
      stateName: false,
      airportPaxCount: 0,
      timerId: null,
      showDetails: false,
      airportName: "",
      passengerPerAirportData: [],
      imageSeriesData: [],
      isRequestingData: false,
      intervalId: null,
      selectedButton: 1,
      tooltipOpen: false
    };
  }

  componentDidMount() {
    const { dispatch, airports } = this.props;

    this.fetchPageData();
    this.configureMapData();

    let intervalId = setInterval(() => {
      if (this.state.selectedButton === 1) {
        this.fetchPageData();
      } else {
        this.fetchInternationalData();
      }
      // this.configureMapData();
    }, 300000);
    this.setState({ intervalId: intervalId });

    if (airports.length === 0) {
      dispatch(commonActions.getAllAirports());
    }
    if(window.location.pathname !== "/dashboard") {
    dispatch(commonActions.setHeaderTitle("DOMESTIC PASSENGERS"));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevState.passengerPerAirportData, this.state.passengerPerAirportData)) {
      // console.log(this.state.passengerPerAirportData);
      let passengerPerAirportData = this.state.passengerPerAirportData;
      let data2_ = {};
      let mapData = passengerPerAirportData.reduce(function(acc, currItem) {
        var key = currItem.AirportCode + '|' + currItem.AirportName;
        // console.log({key})
        if (!data2_[key]) {
          data2_[key] = currItem;
          acc.push(data2_[key]);
        } else {
          data2_[key].value += currItem.value;
        }
        return acc;
      }, []);
      this.polygonSeries.data = [...mapData];
       // console.log("not equal 1")
    }
    if (!_.isEqual(prevState.imageSeriesData, this.state.imageSeriesData)) {
      // console.log("not equal 2");
      this.imageSeries.data = [...this.state.imageSeriesData];
    }

    if (!_.isEqual(prevProps.airports, this.props.airports)) {
      // console.log("not equal 3")
      this.airportSeries.data = [...this.props.airports];
    }


  }

  fetchPageData = () => {
    this.setState({
      passengersToday: 0,
      passengersThisMonth: 0,
      passengersYearToDate: 0,
      passengersYearToDateIntl: 0,
      passengersTrafficData: [],
      passengersStatisticsData: [],
      passengersStatisticsCategory: [],
      passengerPerAirportData: [],
      imageSeriesData: []
    });
    let {
      passengerStartDate,
      passengersEndDate,
      passengersTrafficDate,
      passengersStatisticsDate,
      passengersPerAirportDate
    } = this.state;
    passengerStartDate = moment(passengerStartDate).format("YYYY-MM-DD");
    passengersEndDate = moment(passengersEndDate).format("YYYY-MM-DD");
    passengersTrafficDate = moment(passengersTrafficDate).format("YYYY-MM-DD");
    // passengersStatisticsDate = moment(passengersStatisticsDate).format(
    //   "YYYY-MM-DD"
    // );
    passengersPerAirportDate = moment(passengersPerAirportDate).format(
      "YYYY-MM-DD"
    );
    const { dispatch } = this.props;

    // payloads
    const dailyPassengersPayload = {
      startDate: passengerStartDate,
      endDate: passengersEndDate
    };
    const passengerPerAirlinePayload = {
      date: passengersTrafficDate,
      pageSize: -1
    };
    const passengerPerMonthPayload = { date: passengersStatisticsDate, isLocal: true };
    const passengerPerMonthPayload_ = {
      StartDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      EndDate: moment().format("YYYY-MM-DD"),
      isLocal: true
    };

    const passengersTodayPayload = {
      StartDate: moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
      EndDate: moment().format("YYYY-MM-DD")
    };

    const passengerPerAirportPayload = {
      date: passengersPerAirportDate,
      pageSize: -1,
      isLocal: true
    };

    const passengerYTDPayload = {
      StartDate: moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      EndDate: moment().format("YYYY-MM-DD"),
      IsLocal: true
    };

    // const passengerYTDIntlPayload = {
    //   StartDate: moment()
    //     .startOf("year")
    //     .format("YYYY-MM-DD"),
    //   EndDate: moment().format("YYYY-MM-DD"),
    //   IsLocal: false
    // };

    dispatch(alertActions.startRequest());
    this.setState({ isRequestingData: true });

    Promise.all([
      commonService.fetchDailyPassengersList(dailyPassengersPayload),
      commonService.fetchPassengerPerAirline(passengerPerAirlinePayload),
      commonService.fetchPassengerPerMonth(passengerPerMonthPayload),
      commonService.fetchPassengerPerAirport(passengerPerAirportPayload),
      commonService.fetchTotalPassengerCount(passengersTodayPayload),
      commonService.fetchTotalPassengerCount(passengerPerMonthPayload_),
      commonService.fetchTotalPassengerCount(passengerYTDPayload),
      // commonService.fetchTotalPassengerCount(passengerYTDIntlPayload)
    ])
      .then(response => {
        dispatch(alertActions.stopRequest());

        // console.log({response});
        let dailyPassengersList = response[0].response;
        let passengerPerAirline = response[1].response;
        let passengerPerMonth = response[2].response;
        let passengerPerAirport = response[3].response;
        let passengersToday = response[4].response;
        let passengerPerMonth_ = response[5].response;
        let passengersYearToDate = response[6].response;
        // let passengersYearToDateIntl = response[7].response;

        // console.log({dailyPassengersList})
        this.setState(
          {
            passengersToday:
              passengersToday.PassengerCount === null
                ? 0
                : passengersToday.PassengerCount,
            passengersYesterday:
              dailyPassengersList.DayBeforeLast === null
                ? 0
                : dailyPassengersList.DayBeforeLast.PassengerCount,
            passengersThisMonth:
              passengerPerMonth_.PassengerCount === null
                ? 0
                : passengerPerMonth_.PassengerCount,
            passengersYearToDate:
              passengersYearToDate.PassengerCount === null
                ? 0
                : passengersYearToDate.PassengerCount,
            passengersTrafficData: passengerPerAirline.data === null ? [] : passengerPerAirline.data,
            isRequestingData: false,
            passengersStatisticsData: graphHelpers.chartData(
              passengerPerMonth,
              "passengerCount"
            ),
            passengersStatisticsCategory: graphHelpers.getCategories(
              passengerPerMonth
            ),
            passengerPerAirportData: graphHelpers.renamePassengerMapData(
              passengerPerAirport.data
            ),
            imageSeriesData: this.configureImageSeriesData(
              graphHelpers.renamePassengerMapData(passengerPerAirport.data)
            )
          }
          // () => {
          //   this.configureMapData();
          // }
        );
      })
      .catch(err => {
        dispatch(alertActions.stopRequest());
        // dispatch(alertActions.error(err));
        store.addNotification({
          // title: "",
          message: "Unable to process request",
          type: "danger",
          insert: "top",
          width: 250,
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000
            // onScreen: true
          }
        });
      });
    dispatch(alertActions.startRequest());
  };

  fetchInternationalData = () => {
    this.setState({
      passengersToday: 0,
      passengersThisMonth: 0,
      passengersYearToDate: 0,
      passengersYearToDateIntl: 0,
      passengersTrafficData: [],
      passengersStatisticsData: [],
      passengersStatisticsCategory: [],
      passengerPerAirportData: [],
      imageSeriesData: []
    });
    let {
      passengersStatisticsDate,
    } = this.state;

    // passengersTrafficDate = moment(passengersTrafficDate).format("YYYY-MM-DD");
    // passengersStatisticsDate = moment(passengersStatisticsDate).format(
    //   "YYYY-MM-DD"
    // );
    // passengersPerAirportDate = moment(passengersPerAirportDate).format(
    //     "YYYY-MM-DD"
    // );
    const { dispatch } = this.props;

    // payloads
    // const dailyPassengersPayload = {
    //   startDate: passengerStartDate,
    //   endDate: passengersEndDate
    // };
    const passengerPerAirlinePayload = {
      from:  moment()
          .subtract(1, "month").startOf('month')
          .format("YYYY-MM-DD"),
      to:  moment()
          .subtract(1, "month").endOf('month')
          .format("YYYY-MM-DD"),
      pageSize:1000,
      page: 0,
      isLocal: false
    };
    const passengerPerMonthPayload = { date: passengersStatisticsDate, isLocal: false };
    const passengerPerMonthPayload_ = {
      StartDate: moment()
          .subtract(1, "month").startOf("month")
          .format("YYYY-MM-DD"),
      EndDate: moment()
          .subtract(1, "month").endOf('month')
          .format("YYYY-MM-DD"),
      IsLocal: false
    };

    const passengersTodayPayload = {
      StartDate: moment()
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
      EndDate: moment().format("YYYY-MM-DD"),
      isLocal: false
    };

    // const passengerPerAirportPayload = {
    //   from:  moment()
    //       .subtract(1, "month").startOf('month')
    //       .format("YYYY-MM-DD"),
    //   to:  moment()
    //       .subtract(1, "month").endOf('month')
    //       .format("YYYY-MM-DD"),
    //   pageSize: 1000,
    //   page: 0,
    //   isLocal: false,
    // };

    const passengerPerAirportTrafficPayload = {
      from:  moment()
          .subtract(1, "month").startOf('month')
          .format("YYYY-MM-DD"),
      to:  moment()
          .subtract(1, "month").endOf('month')
          .format("YYYY-MM-DD"),
      pageSize: -1,
      isLocal: false,
    };

    // const passengerYTDPayload = {
    //   StartDate: moment()
    //       .startOf("year")
    //       .format("YYYY-MM-DD"),
    //   EndDate: moment().format("YYYY-MM-DD"),
    //   isLocal: false
    // };

    const passengerYTDIntlPayload = {
      StartDate: moment()
          .startOf("year")
          .format("YYYY-MM-DD"),
      EndDate: moment().format("YYYY-MM-DD"),
      IsLocal: false
    };

    dispatch(alertActions.startRequest());
    this.setState({ isRequestingData: true });

    Promise.all([
      // commonService.fetchDailyPassengersList(dailyPassengersPayload),
      commonService.fetchIntlPassengerPerAirline(passengerPerAirlinePayload),
      commonService.fetchPassengerPerMonth(passengerPerMonthPayload),
      commonService.fetchIntlPassengerAirportTraffic(passengerPerAirportTrafficPayload),
      commonService.fetchTotalPassengerCount(passengersTodayPayload),
      commonService.fetchTotalPassengerCount(passengerPerMonthPayload_),
      // commonService.fetchTotalPassengerCount(passengerYTDPayload),
      commonService.fetchTotalPassengerCount(passengerYTDIntlPayload)
    ])
        .then(response => {
          dispatch(alertActions.stopRequest());

          // console.log({response});
          // let dailyPassengersList = response[0].response;
          let passengerPerAirline = response[0].response;
          let passengerPerMonth = response[1].response;
          let passengerPerAirport = response[2].response;
          let passengersToday = response[3].response;
          let passengerPerMonth_ = response[4].response;
          // let passengersYearToDate = response[6].response;
          let passengersYearToDateIntl = response[5].response;

          // console.log({dailyPassengersList})
          this.setState(
              {
                passengersToday:
                    passengersToday.PassengerCount === null
                        ? 0
                        : passengersToday.PassengerCount,
                passengersThisMonth:
                    passengerPerMonth_.PassengerCount === null
                        ? 0
                        : passengerPerMonth_.PassengerCount,
                passengersYearToDateIntl:
                    passengersYearToDateIntl.PassengerCount === null
                        ? 0
                        : passengersYearToDateIntl.PassengerCount,
                passengersTrafficData: passengerPerAirline.data === null ? [] : passengerPerAirline.data,
                isRequestingData: false,
                passengersStatisticsData: graphHelpers.chartData(
                    passengerPerMonth,
                    "passengerCount"
                ),
                passengersStatisticsCategory: graphHelpers.getCategories(
                    passengerPerMonth
                ),
                passengerPerAirportData: graphHelpers.renamePassengerMapData(
                    passengerPerAirport.data
                ),
                imageSeriesData: this.configureImageSeriesData(
                    graphHelpers.renamePassengerMapData(passengerPerAirport.data)
                )
              }
              // () => {
              //   this.configureMapData();
              // }
          );
        })
        .catch(err => {
          dispatch(alertActions.stopRequest());
          // dispatch(alertActions.error(err));
          store.addNotification({
            // title: "",
            message: "Unable to process request",
            type: "danger",
            insert: "top",
            width: 250,
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000
              // onScreen: true
            }
          });
        });
    dispatch(alertActions.startRequest());
  };

  configureImageSeriesData = data => {
    // console.log({data})
    // let mapData = [...data];

    let data_ = [...data].filter(item =>
    item.Latitude !== null & item.Longitude !== null).map(item => {
      item.airport_latitude = parseFloat(item.Latitude);
      item.airport_longitude = parseFloat(item.Longitude);
          return item;
        })

    let data2_ = {}
    let mapData = data_.reduce(function(acc, currItem) {
      var key = currItem.AirportCode + '|' + currItem.AirportName;
      // console.log({key})
      if (!data2_[key]) {
        data2_[key] = currItem;
        acc.push(data2_[key]);
      } else {
        data2_[key].value += currItem.value;
      }
      return acc;
    }, []);
    // console.log(mapData)
    //lat and lng for states which at the moment is not being used
    // let latlong = {
    //   "NG-AB": { latitude: 5.4309, longitude: 7.5247 },
    //   "NG-AD": { latitude: 9.325, longitude: 12.4381 },
    //   "NG-AK": { latitude: 4.93, longitude: 7.8722 },
    //   "NG-AN": { latitude: 6.2758, longitude: 7.0068 },
    //   "NG-BA": { latitude: 10.6371, longitude: 10.0807 },
    //   "NG-BY": { latitude: 4.8678, longitude: 5.8987 },
    //   "NG-BE": { latitude: 7.3508, longitude: 8.8363 },
    //   "NG-BO": { latitude: 12.1205, longitude: 13.174 },
    //   "NG-CR": { latitude: 6.167, longitude: 8.6601 },
    //   "NG-DE": { latitude: 5.5325, longitude: 5.8987 },
    //   "NG-EB": { latitude: 6.178, longitude: 7.9593 },
    //   "NG-ED": { latitude: 6.5438, longitude: 5.8987 },
    //   "NG-EK": { latitude: 7.6656, longitude: 5.3103 },
    //   "NG-EN": { latitude: 6.6069, longitude: 7.5247 },
    //   "NG-FC": { latitude: 9.0765, longitude: 7.3986 },
    //   "NG-GO": { latitude: 10.2464, longitude: 11.1617 },
    //   "NG-IM": { latitude: 5.6039, longitude: 7.0068 },
    //   "NG-JI": { latitude: 12.446, longitude: 9.7233 },
    //   "NG-KD": { latitude: 10.159, longitude: 8.1339 },
    //   "NG-KN": { latitude: 11.7574, longitude: 8.6601 },
    //   "NG-KT": { latitude: 12.5139, longitude: 7.6114 },
    //   "NG-KE": { latitude: 11.6781, longitude: 4.0695 },
    //   "NG-KO": { latitude: 7.9075, longitude: 6.5783 },
    //   "NG-KW": { latitude: 8.9848, longitude: 4.5624 },
    //   "NG-LA": { latitude: 6.5244, longitude: 3.3792 },
    //   "NG-NA": { latitude: 8.5705, longitude: 8.3088 },
    //   "NG-NI": { latitude: 10.2155, longitude: 5.394 },
    //   "NG-OG": { latitude: 6.9075, longitude: 3.5813 },
    //   "NG-ON": { latitude: 6.8959, longitude: 4.8936 },
    //   "NG-OS": { latitude: 7.5876, longitude: 4.5624 },
    //   "NG-OY": { latitude: 8.1196, longitude: 3.4196 },
    //   "NG-PL": { latitude: 9.2446, longitude: 9.3673 },
    //   "NG-RI": { latitude: 4.8581, longitude: 6.9209 },
    //   "NG-SO": { latitude: 12.9374, longitude: 5.2267 },
    //   "NG-TA": { latitude: 8.0055, longitude: 10.26 },
    //   "NG-YO": { latitude: 12.1871, longitude: 11.7068 },
    //   "NG-ZA": { latitude: 12.1844, longitude: 6.2376 }
    //   // "NG-NA": {"latitude":8.5705, "longitude":8.3088},
    // };

    // for (let i = 0; i < mapData.length; i++) {
    //   // console.log(mapData[i]);
    //   mapData[i].latitude = latlong[mapData[i].id].latitude;
    //   mapData[i].longitude = latlong[mapData[i].id].longitude;
    // }

    return mapData;
  };

  configureMapData = () => {
    const { passengerPerAirportData, imageSeriesData } = this.state;
  const {airports} = this.props

    //color: #c42c21
    //map data
    // let mapData = mapData1;
    //
    // let mapData = [
    //   {
    //     id: "NG-LA",
    //     name: "Lagos",
    //     value: 2159,
    //     fill: am4core.color("#c42c21"),
    //     airportName: "Muritala Mohammed Airport",
    //     Latitude: 6.5244,
    //     Longitude: 3.3792
    //   },
    //   {
    //     id: "NG-FC",
    //     name: "Abuja",
    //     value: 50,
    //     fill: am4core.color("#c42c21"),
    //     airportName: "Nnamdi Azikwe Airport",
    //     Latitude: 8.985590,
    //     Longitude: 7.3986
    //   },
    //   {
    //     id: "NG-ZA",
    //     name: "Zamfara",
    //     value: 10500,
    //     fill: am4core.color("#c42c21"),
    //     airportName: "",
    //     Latitude: 12.1844,
    //     Longitude: 6.2376
    //   }
    //     {
    //     "id": "NG-NA",
    //     "name": "Nasarawa",
    //     "value": 210500,
    //     "fill": am4core.color("#c42c21")
    // }
    // ];

    // Add lat/long information to data
    // for (var i = 0; i < mapData.length; i++) {
    //   console.log(mapData[i]);
    //   mapData[i].Latitude = latlong[mapData[i].StateCode].latitude;
    //   mapData[i].Longitude = latlong[mapData[i].StateCode].longitude;
    // }

    // for (var i = 0; i < mapData.length; i++) {
      // console.log(mapData[i]);
    //   mapData[i].latitude = latlong[mapData[i].id].latitude;
    //   mapData[i].longitude = latlong[mapData[i].id].longitude;
    // }

    var chart = am4core.create("chartdiv", am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodata_region_nigeriaLow;
    chart.maxZoomLevel = 1;

    // chart.background.fill = am4core.color("#242933");
    // chart.background.fillOpacity = 1;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    this.polygonSeries = polygonSeries;

    //map shadow effect
    var shadow = polygonSeries.filters.push(new am4core.DropShadowFilter());
    shadow.dx = 1;
    shadow.dy = 1;
    shadow.blur = 5;
    shadow.opacity = 0.5;

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Configure series
    var polygonTemplate = polygonSeries.mapPolygons.template;

    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#74B266");

    let airportSeries = chart.series.push(new am4maps.MapImageSeries());
    let airportSeriesTemplate = airportSeries.mapImages.template;
    this.airportSeries = airportSeries;

    // set up airport marker
    let airportMarker = airportSeriesTemplate.createChild(am4core.Image);
    airportMarker.href = `${AirportMarker}`;
    airportMarker.width = 12;
    airportMarker.height = 12;
    airportMarker.nonScaling = true;
    airportMarker.tooltipText = "{Name}";
    airportMarker.horizontalCenter = "middle";
    airportMarker.verticalCenter = "bottom";
    
    // set airport series template property fields
    airportSeriesTemplate.propertyFields.latitude = "pos_latitude";
    airportSeriesTemplate.propertyFields.longitude = "pos_longitude";

    airportSeries.data = [...airports];

    //Add Data
    polygonSeries.data = [...passengerPerAirportData];

    // polygonSeries.data = [
    //   {
    //     id: "NG-LA",
    //     name: "Lagos",
    //     value: 2159,
    //     fill: am4core.color("#F05C5C"),
    //     airportName: "Muritala Mohammed Airport"
    //   },
    //   {
    //     id: "NG-FC",
    //     name: "Abuja",
    //     value: 50,
    //     fill: am4core.color("#5C5CFF"),
    //     airportName: "Nnamdi Azikwe Airport"
    //   },
    //   {
    //     id: "NG-ZA",
    //     name: "Zamfara",
    //     value: 10500,
    //     fill: am4core.color("#c42c21"),
    //     airportName: ""
    //   }
    // ];

    // Create hover state and set alternative fill color
    var hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#367B25");

    let imageSeries = chart.series.push(new am4maps.MapImageSeries());
    this.imageSeries = imageSeries;
    imageSeries.data = [...imageSeriesData];
    imageSeries.dataFields.value = "value";

    let imageTemplate = imageSeries.mapImages.template;
    imageTemplate.propertyFields.latitude = "airport_latitude";
    imageTemplate.propertyFields.longitude = "airport_longitude";
    imageTemplate.nonScaling = true;

    let circle = imageTemplate.createChild(am4core.Circle);
    circle.fillOpacity = 0.7;
    circle.propertyFields.fill = "fill";
    circle.tooltipText = "{AirportName}: [bold]{value}[/]";

    let label = imageTemplate.createChild(am4core.Label);
    label.text = "{value}";
    label.fill = am4core.color("#fff");
    label.verticalCenter = "middle";
    label.horizontalCenter = "middle";
    label.nonScaling = true;

    imageSeries.heatRules.push({
      target: circle,
      property: "radius",
      min: 12,
      max: 30,
      dataField: "value"
    });

    //onClick event
    polygonTemplate.events.on("hit", ev => {
      // zoom to an object
      ev.target.series.chart.zoomToMapObject(ev.target);
      // get object info
      let clickedArea = ev.target.dataItem.dataContext;
      let stateName = ev.target.dataItem.dataContext.name;
      let mapValue = ev.target._dataItem.dataContext.value;
      let airportName = ev.target.dataItem.dataContext.AirportName;

      chart.goHome();

      polygonSeries.getPolygonById(clickedArea.id).fill = am4core.color("#f00");

      this.setState({
        stateName:
          stateName  === "Abuja Federal Capital Territory"
            ? "Abuja"
            : stateName,
        airportPaxCount: mapValue,
        showDetails: true,
        airportName
      });

      // if(timerId !== null) {
      //   clearTimeout(timerId);
      // }
      // if (!document.getElementById("passenger-card").classList.contains("show-passenger-card")) {
      //     this.toggle();
      // }
      //
      // let timeoutId = setTimeout(() => this.toggle(), 50000);
      // this.setState({ timerId: timeoutId });
    });
  };

  // toggle = () => {
  //   // document.getElementById("flight-card").css("disp")
  //   // console.log("clicked")
  //   document.getElementById("passenger-card").classList.toggle("show-passenger-card");
  // };

  removeCard = () => {
    this.setState({ stateName: false });
    // clearTimeout(this.state.timerId);
    // this.toggle();
  };

  mouseLeave = () => {
      // let statesCard = document.getElementById('passenger-card');
      // console.log({statesCard});
      // console.log(statesCard.childNodes)
      // statesCard.style.display = "none";
      this.setState({stateName: false})

      //Delay hiding card
      // setTimeout(() => {
      //     this.setState({stateName: false})
      // }, 1000)
  }

  ToggleRoutes = selected => {
    // let { response } = this.state;
    const { dispatch } = this.props;

    this.setState({
      selectedButton: selected,
    }, () => {
      if (this.state.selectedButton === 1) {
        this.fetchPageData();
        this.configureMapData();
        if (window.location.pathname !== "/dashboard") {
          dispatch(commonActions.setHeaderTitle("DOMESTIC PASSENGERS"));
        }
      }
      if (this.state.selectedButton === 2) {
        this.fetchInternationalData();
        this.configureMapData();
        if (window.location.pathname !== "/dashboard") {
          dispatch(commonActions.setHeaderTitle("INTERNATIONAL PASSENGERS"));
        }
      }
    });

    // if (response === 0) {
    //   this.fetchPageData();
    // }

  };

  toggle = () => {

  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    const {
      passengersToday,
      passengersThisMonth,
      passengersTrafficData,
      airportPaxCount,
      stateName,
      passengersYearToDate,
      passengersYearToDateIntl,
      airportName,
      isRequestingData,
      passengersStatisticsCategory,
      passengersStatisticsData,
      selectedButton
    } = this.state;

    return (
      <div>
        <Row>
          <Col md={12} className="d-flex justify-content-center">
             <Toggle
                 button1Text={"Domestic"}
                 button2Text={"International"}
                 setButtonSelected={this.ToggleRoutes}
                 selectedButton={selectedButton}
             />
          </Col>
        </Row>
          {
            selectedButton === 1 &&
                <Row>
                    <PassengersCard
                        col={4}
                        style={{
                          background: "#4d7f2c",
                          borderColor: "#39601f",
                          borderRadius: "15px",
                          color: "#fff"
                        }}
                        title={"Passengers"}
                        subTitle={"As at today"}
                        icon={<i className="fas fa-users fa-2x"></i>}
                        data={appHelpers.numberWithCommasOnly(passengersToday)}
                        // route={"Domestic"}
                    />
                    <PassengersCard
                    col={4}
                    style={{
                    background: "#204383",
                    borderColor: "#204383",
                    borderRadius: "15px",
                    color: "#fff"
                  }}
                    title={"Passengers"}
                    subTitle={"Month to date"}
                    icon={<i className="fas fa-users fa-2x"></i>}
                    data={appHelpers.formatLargeNumbersWithDecimals(passengersThisMonth)}
                    // route={"Domestic"}
                    />
                    <PassengersCard
                    col={4}
                    style={{
                    background: "#2f2f2f",
                    borderColor: "#2f2f2f",
                    borderRadius: "15px",
                    color: "#fff"
                  }}
                    title={"Passengers"}
                    subTitle={"Year to date"}
                    icon={<i className="fas fa-users fa-2x"></i>}
                    data={appHelpers.formatLargeNumbersWithDecimals(
                      passengersYearToDate
                  )}
                    // route={"Domestic"}
                    />
                </Row>

          }
        {
          selectedButton === 2 &&
          <Row>
            <PassengersCard
                col={6}
                style={{
                  background: "#204383",
                  borderColor: "#204383",
                  borderRadius: "15px",
                  color: "#fff"
                }}
                title={"Passengers"}
                subTitle={"Month to date"}
                icon={<i className="fas fa-users fa-2x"></i>}
                data={appHelpers.formatLargeNumbersWithDecimals(passengersThisMonth)}
                // data={appHelpers.numberWithCommasOnly(passengersThisMonth)}
                // route={"Domestic"}
            />
            <PassengersCard
                col={6}
                style={{
                  background: "#2f2f2f",
                  borderColor: "#2f2f2f",
                  borderRadius: "15px",
                  color: "#fff"
                }}
                title={"Passengers"}
                subTitle={"Year to date"}
                icon={<i className="fas fa-users fa-2x"></i>}
                data={appHelpers.formatLargeNumbersWithDecimals(
                    passengersYearToDateIntl
                )}
                // route={"Domestic"}
            />
          </Row>

        }

          {/*<PassengersYearToDataCard*/}
            {/*col={5}*/}
            {/*style={{*/}
              {/*background: "#2f2f2f",*/}
              {/*borderColor: "#2f2f2f",*/}
              {/*borderRadius: "15px",*/}
              {/*color: "#fff"*/}
            {/*}}*/}
            {/*title1={"Passengers"}*/}
            {/*subTitle1={"Year to date"}*/}
            {/*icon1={<i className="fas fa-users fa-2x"></i>}*/}
            {/*data1={appHelpers.formatLargeNumbersWithDecimals(*/}
              {/*passengersYearToDate*/}
            {/*)}*/}
            {/*data2={appHelpers.formatLargeNumbersWithDecimals(*/}
              {/*passengersYearToDateIntl*/}
            {/*)}*/}
          {/*/>*/}
        {/*</Row>*/}

        <Row>
          <Col sm={12}>
            <Card className="shadow">
              <CardBody className={""} style={{ padding: "1rem" }}>
                <div className={"passengers-map"} id="chartdiv" />
              </CardBody>
            </Card>
            {stateName !== false && (
              <Card className="passenger-card chart-card" id="passenger-card"  onMouseLeave={this.mouseLeave}>
                <span className="lstick bg-success"></span>

                <div className="bg-white stats-bar">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="p-3 w-100 text-truncate">
                        <h3 className="text-secondary">
                          <i className="mdi mdi-counter mr-2"> </i>
                          Passenger Count Per Airport
                          <span
                            onClick={this.removeCard}
                            className="pull-right clickable close-icon"
                            data-effect="fadeOut"
                          >
                            <i className="fa fa-times"></i>
                          </span>
                        </h3>
                        ​
                        {stateName !== false && (
                          <div>
                            <h5 style={{ marginBottom: 0, fontWeight: "500" }}>{airportName}</h5>
                            <p style={{ fontSize: "38px", fontWeight: "bold" }}>
                              {appHelpers.numberWithCommasOnly(airportPaxCount)}
                              <span style={{ fontSize: "18px", fontWeight: "light" }}>
                                {airportPaxCount > 0
                                  ? " Passengers"
                                  : "No Passengers"}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <CardBody>
                  ​
                  {stateName !== false && (
                    <Card
                      style={{
                        background: "#f0f0f0",
                        borderColor: "#f0f0f0",
                        borderRadius: "15px",
                        marginTop: "-4rem"
                        // maxHeight: "50%"
                      }}
                    >
                      {/* <CardBody> */}
                      <Row>
                        <Col md={"12"}>
                          <div className={"align-items-center"}>
                            <Nigeria
                              stateName={stateName}
                              hoverColor="red"
                              hoverBackgroundColor="grey"
                              defaultColor="green"
                              id="plat"
                              height={"200.0226150793651"}
                              style={{textAlign: "center", height: "100px" }}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* </CardBody> */}
                    </Card>
                  )}
                </CardBody>
              </Card>
            )}
            {/*</Col>*/}
            {/*</Row>*/}
            {/*</CardBody>*/}
            {/*</Card>*/}
          </Col>
          <BottomCard
            title={ selectedButton === 1 ? "Passenger Traffic Today" : "Passenger Traffic This Month"}
            cardName={"bottom-card"}
            // route={"Domestic"}
          >
            <PassengerTrafficTable
              passengersTrafficData={passengersTrafficData}
              isRequestingData={isRequestingData}
            />
          </BottomCard>
          <BottomCard
            title={"Passenger Count Per Month"}
            cardName={"bottom-card-right"}
            // route={"Domestic"}
          >
            <div className="chart-wrapper">
              <Chart
                options={BarChartOptions(
                  ["#2b7b9d"],
                  ["#745af2", "#263238"],
                  passengersStatisticsCategory,
                  false,
                  "Month",
                  "Count",
                  ""
                )}
                series={[
                  {
                    name: "",
                    data: passengersStatisticsData
                  }
                ]}
                type="bar"
                height="280"
              />
            </div>
            {/*</CardBody>*/}
            {/*</Card>*/}
            {/*</Col>*/}
            {/*</Row>*/}
            {/*</CardBody>*/}
            {/*</Card>*/}
          </BottomCard>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { requesting, user, airports } = state;
  return {
    requesting,
    user,
    airports
  };
}

export default connect(mapStateToProps)(Passengers);
