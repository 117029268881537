import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Flights from "../flights/Flights";
import Revenue from "../revenue/Revenue";
import Passengers from "../passengers/Passengers";
import LagosCCTV from "../cctv/LagosOutCCTV";
import { commonActions } from "../../_actions";
import { connect } from "react-redux";

class DashboardSlides extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(commonActions.setHeaderTitle("DASHBOARD"));
  }

  render() {
    return (
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={30000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 1,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 1,
            partialVisibilityGutter: 30
          }
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        
        <div>
          <Flights />
        </div>
        <div>
          <Passengers />
        </div>
        <div>
          <Revenue />
        </div>
        <div>
          <LagosCCTV />
        </div>
      </Carousel>
    );
  }
}

export default connect(null)(DashboardSlides);
