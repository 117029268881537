import React from "react";
import { Table } from "reactstrap";
import { EmptyRecord } from "./../../utilities/EmptyRecord";
import { ProgressLoader } from "./../../components/progressloader/ProgressLoader";
import { appHelpers } from "./../../_helpers";

const passengerTrafficTable = ({passengersTrafficData, isRequestingData}) => {
    return (
        <div className="container">

            {/* fetching data */}
            {isRequestingData && (
                <ProgressLoader message="Loading Passenger Data..." />
            )}

            {/* when no record */}
            {!isRequestingData && passengersTrafficData.length === 0 && (
                <EmptyRecord message="No Passenger Data." />
            )}

            {/* when there are records */}
            {!isRequestingData && passengersTrafficData.length > 0 && (
                <Table className="table table-scroll table-striped hover">
                    <thead>
                    <tr>
                        {/*<th scope="col">#</th>*/}
                        <th scope="col">Airline</th>
                        <th scope="col">Flights</th>
                        <th scope="col">Passengers</th>
                    </tr>
                    </thead>
                    <tbody>
                    {passengersTrafficData.map((record, index) => {
                        return (
                            <tr key={index} className="v-middle">
                                {/*<td>{"A"}</td>*/}
                                {/*<td>{record.AirlineName}</td>*/}
                                <td><img className="img img-responsive" height={"50px"} src={`/images/airlines/${appHelpers.interpretAirline(record.AirlineId, record.AirlineName)}`} alt="airline"/></td>
                                <td>{record.FlightCount}</td>
                                <td>{record.PassengerCount}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default passengerTrafficTable;