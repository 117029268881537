import React from "react";

const styles =  {
    height: "90vh",
    display: "inline-block",
    margin: "0 auto"
  }

const Iframe = ({ src, height, width }) => {
  return (
    <div>
      <iframe title="cctv-iframe" src={src} height={height} width={width} style={styles} />
    </div>
  );
};

export default Iframe;
