import React from "react";

import {
  Card,
  CardBody,
  ListGroup,
  ListGroupItemHeading,
  ListGroupItemText
} from "reactstrap";
import { ProgressLoader } from "../../../components/progressloader/ProgressLoader";

const FlightOverlayCard = ({
  selectedFlightDetails,
  closeFlightCard,
  requestingDetails
}) => {
  return (
    <div className="flight-card" id="flight-card">
      <Card className="shadow rounded mb-0">
        <span className="lstick bg-success"></span>

        <div className="bg-white stats-bar">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="p-3 w-100 text-truncate">
                <h3 className="text-secondary">
                  <i className="mdi mdi-format-list-bulleted mr-2"> </i>
                  Flight details
                  <span
                    onClick={closeFlightCard}
                    className="pull-right clickable close-icon"
                    data-effect="fadeOut"
                  >
                    <i className="fa fa-times"></i>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <CardBody className="pt-0">
          {requestingDetails && (
            <ProgressLoader message="Loading flight details..." />
          )}

          {!requestingDetails && (
            <ListGroup flush>
              <ListGroupItemHeading className="primary">
                Airline
              </ListGroupItemHeading>
              <ListGroupItemText>
                {selectedFlightDetails.Airline
                  ? selectedFlightDetails.Airline
                  : ""}
              </ListGroupItemText>
              <ListGroupItemHeading>Departure Location</ListGroupItemHeading>
              <ListGroupItemText>
                {selectedFlightDetails.start_location
                  ? selectedFlightDetails.start_location
                  : ""}
              </ListGroupItemText>
              <ListGroupItemHeading>Current Location</ListGroupItemHeading>
              <ListGroupItemText>
                {selectedFlightDetails.location
                  ? selectedFlightDetails.location
                  : ""}
              </ListGroupItemText>
              <ListGroupItemHeading>Flight Duration</ListGroupItemHeading>
              <ListGroupItemText>
                {selectedFlightDetails.flight_duration
                  ? selectedFlightDetails.flight_duration
                  : ""}
              </ListGroupItemText>
            </ListGroup>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default FlightOverlayCard;
