import React from "react";
import { connect } from "react-redux";
import { commonService } from "../../_services";

import "leaflet/dist/leaflet.css";
// import InboundFlightIcon from "../../assets/images/inbound.png";
import InboundFlightIcon from "../../assets/images/inbound.png";
// import OutboundFlightIcon from "../../assets/images/inbound2.png";
import OutboundFlightIcon from "../../assets/images/outbound.png";
import NigeriaMapIcon from "../../assets/images/ng-map.png";

import { Row, Col, Card, CardBody } from "reactstrap";
import FlightsMap from "./flightsmap";
import FlightOverlayCard from "./flightoverlaycard";
import { appConstants } from "../../_constants";
import { alertActions, commonActions } from "../../_actions";
import { appHelpers } from "../../_helpers";

import "./index.css";
import moment from "moment";
import BottomCard from "../../components/bottomcard/BottomCard";
import FlightsScheduleTable from "./flightsscheduletable";
import { store } from "react-notifications-component";
import _ from "lodash";

class Flights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latCenter: 9.082,
      lngCenter: 8.6753,
      // lat: 6.57985,
      // lng: 3.34453,
      zoom: 7,
      inboundFlights: 0,
      outBoundFlights: 0,
      totalFlights: 0,
      mapData: [],
      selectedFlightDetails: null,
      totalCount: 0,
      timerId: null,
      flightsScheduleData: [],
      intervalId: null,
      showDetailsCard: false,
      isRequestingSchedule: false,
      ws: null,
      streamData: {}
    };
  }

  componentDidMount() {
    const { dispatch, airlines, airports } = this.props;
    // const { endpoint } = this.state;

    this.fetchFlightsSchedule();
    this.fetchFlightsData();

    // fetch flights map data after a specified interval
    // let intervalId = setInterval(() => this.fetchFlightsData(), 60000);
    // this.setState({ intervalId: intervalId });

    if (airlines.length === 0) {
      dispatch(commonActions.getAllAirlines());
    }
    if (airports.length === 0) {
      dispatch(commonActions.getAllAirports());
    }

    if (window.location.pathname !== "/dashboard") {
      dispatch(commonActions.setHeaderTitle("FLIGHTS"));
    }
  }

  timeout = 250; // Initial timeout duration as a class variable

  /**
   * @function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = () => {
    var ws = new WebSocket(appConstants.STREAM_URL + appConstants.STREAM_KEY);
    // var ws = new WebSocket("wss://echo.websocket.org/");
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
      console.log("connected websocket main component");

      this.setState({ ws: ws });

      that.timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };
    ws.onmessage = evt => {
      if (evt.data) {
        this.setState({ streamData: JSON.parse(evt.data) });
      }
    };

    // websocket onclose event listener
    ws.onclose = e => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );

      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  updateFlightsData = () => {
    const { streamData, mapData } = this.state;
    // if there is some data
    if (mapData.length > 0) {
      // check if icao number exists
      const foundIcao = mapData.filter(item => item.icao === streamData.icao);

      // same icao
      if (foundIcao.length > 0) {
        let updatedMapData = mapData
          .map(item => {
            if (item.icao === streamData.icao) {
              item.Airline = streamData.icao;
              item.end_latitude = streamData.latitude;
              item.end_longitude = streamData.latitude;
              item.onground = streamData.onground;
              item.isInBound = streamData.isInBound;
              item.multiGeoLine[0].push({
                latitude: streamData.latitude,
                longitude: streamData.longitude
              });
            }

            if (item.multiGeoLine[0].length > 2) {
              item.multiGeoLine[0].splice(0, item.multiGeoLine[0].length - 2);
            }

            return item;
          })
          .filter(item => item.onground !== true);
        this.setState({ mapData: updatedMapData });
      } else {
        // different icao
        const streamData2 = JSON.parse(JSON.stringify(streamData));
        streamData2.Airline = streamData2.icao;
        streamData2.end_latitude = streamData2.latitude;
        streamData2.end_longitude = streamData2.longitude;

        let coordinateObject = {
          latitude: streamData2.latitude,
          longitude: streamData2.longitude
        };

        streamData2.multiGeoLine = [[coordinateObject]];
        if (streamData2.multiGeoLine[0].length > 2) {
          streamData2.multiGeoLine[0].splice(
            0,
            streamData2.multiGeoLine[0].length - 2
          );
        }

        let updatedMapData = [...mapData, streamData2].filter(
          item => item.onground !== true
        );
        this.setState({ mapData: updatedMapData });
      }
    } else {
      // no initial data
      const streamData2 = JSON.parse(JSON.stringify(streamData));
      streamData2.Airline = streamData2.icao;
      streamData2.end_latitude = streamData2.latitude;
      streamData2.end_longitude = streamData2.latitude;

      streamData2.multiGeoLine = [
        [{ latitude: streamData2.latitude, longitude: streamData2.longitude }]
      ];

      //
      if (streamData2.multiGeoLine[0].length > 2) {
        streamData2.multiGeoLine[0].splice(
          0,
          streamData2.multiGeoLine[0].length - 2
        );
      }

      let updatedMapData = [...mapData, streamData2].filter(
        item => item.onground !== true
      );

      this.setState({ mapData: updatedMapData });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.streamData, this.state.streamData)) {
      this.updateFlightsData();
    }
    if (!_.isEqual(prevState.mapData, this.state.mapData)) {
      this.updateFlightsCount();
    }
  }

  updateFlightsCount = () => {
    const { mapData } = this.state;

    const inboundCount = mapData.filter(item => item.isInBound === true).length;
    const outboundCount = mapData.filter(item => item.isInBound === false)
      .length;

    const totalFlights = inboundCount + outboundCount;

    this.setState({
      inboundFlights: inboundCount,
      outBoundFlights: outboundCount,
      totalFlights: totalFlights
    });
  };

  fetchFlightsSchedule = () => {
    const { dispatch } = this.props;

    let day = moment().format("dddd");
    let dayId = appHelpers.fetchDayId(day);

    this.setState({ isRequestingSchedule: true });
    dispatch(alertActions.startRequest());

    commonService.fetchFlightsSchedule(dayId).then(res => {
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          isRequestingSchedule: false,
          flightsScheduleData: res.response.Flights
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        store.addNotification({
          // title: "",
          message: appHelpers.interpretErrorResponse(res.response),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000
            // onScreen: true
          }
        });
      }
    });
  };

  fetchFlightsData = () => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());

    commonService.fetchFlightsData().then(res => {
      dispatch(alertActions.stopRequest());

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        // add multigeoline data initially
        let updatedMapData = res.response.ResultList.map(item => {
          item.multiGeoLine = [[...item.coordinates]];
          return item;
        });

        this.setState({
          mapData: updatedMapData,
          totalCount: res.response.TotalCount,
          inboundFlights: res.response.Inbound,
          outBoundFlights: res.response.Outbound,
          totalFlights: res.response.TotalCount
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        // dispatch(alertActions.error(res.response));
        store.addNotification({
          // title: "",
          message: res.response,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000
            // onScreen: true
          }
        });
      }

      // initialise socket
      this.connect();
    });
  };

  toggleFlightCard = () => {
    // document.getElementById("flight-card").classList.toggle("show-flight-card");
    this.setState({ showDetailsCard: !this.state.showDetailsCard });
  };

  handleMarkerClick = markerObject => {
    const { timerId, showDetailsCard } = this.state;
    const { dispatch } = this.props;

    this.setState({ isRequestingDetails: true });
    if (showDetailsCard === false) {
      this.toggleFlightCard();
    }

    let coordinatesPayload = {
      startLatitude: parseFloat(markerObject.start_latitude),
      startLongitude: parseFloat(markerObject.start_longitude),
      endLatitude: parseFloat(
        markerObject.end_latitude === null
          ? markerObject.start_latitude
          : markerObject.end_latitude
      ),
      endLongitude: parseFloat(
        markerObject.end_longitude === null
          ? markerObject.start_longitude
          : markerObject.end_longitude
      )
    };
    dispatch(alertActions.startRequest());

    commonService.fetchCoordinatesDetails(coordinatesPayload).then(res => {
      dispatch(alertActions.stopRequest());
      // For start location details
      if (res.startResponse.status === appConstants.SUCCESS_RESPONSE) {
        markerObject.start_location = res.startResponse.response.display_name;
      } else if (res.startResponse.status === appConstants.ERROR_RESPONSE) {
        console.log(
          appHelpers.interpretErrorResponse(res.endResponse.response)
        );

        // store.addNotification({
        //   // title: "",
        //   message: appHelpers.interpretErrorResponse(
        //     res.startResponse.response
        //   ),
        //   type: "danger",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animated", "fadeIn"],
        //   animationOut: ["animated", "fadeOut"],
        //   dismiss: {
        //     duration: 5000
        //     // onScreen: true
        //   }
        // });
      }

      // For end location details
      if (res.endResponse.status === appConstants.SUCCESS_RESPONSE) {
        markerObject.location = res.endResponse.response.display_name;
      } else if (res.endResponse.status === appConstants.ERROR_RESPONSE) {
        console.log(
          appHelpers.interpretErrorResponse(res.endResponse.response)
        );
        // store.addNotification({
        //   // title: "",
        //   message: appHelpers.interpretErrorResponse(res.endResponse.response),
        //   type: "danger",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animated", "fadeIn"],
        //   animationOut: ["animated", "fadeOut"],
        //   dismiss: {
        //     duration: 5000
        //     // onScreen: true
        //   }
        // });
      }

      this.setState({
        selectedFlightDetails: markerObject,
        isRequestingDetails: false
      });

      if (timerId !== null) {
        clearTimeout(timerId);
      }

      let timeoutId = setTimeout(() => this.toggleFlightCard(), 60000);
      this.setState({ timerId: timeoutId });
    });
  };

  closeFlightCard = () => {
    clearTimeout(this.state.timerId);
    this.toggleFlightCard();
  };

  componentWillUnmount() {
    // clearInterval(this.state.intervalId);
    // this.state.ws.close();
  }

  render() {
    const {
      mapData,
      inboundFlights,
      outBoundFlights,
      totalFlights,
      selectedFlightDetails,
      flightsScheduleData,
      isRequestingSchedule,
      showDetailsCard,
      isRequestingDetails
    } = this.state;

    return (
      <div>
        <Row>
          <Col sm={4} md={4} lg={4}>
            <Card
              style={{
                background: "#4d7f2c",
                borderColor: "#39601f",
                borderRadius: "15px",
                color: "#fff",
                marginBottom: "10px"
              }}
            >
              <span className="lstick widget-card bg-white"></span>
              <CardBody>
                <div className="d-flex">
                  <div className="mr-3 align-self-center">
                    <img
                      style={{ height: "100px" }}
                      src={InboundFlightIcon}
                      alt="inbound flight"
                    />
                    {/* <i class="fas fa-plane-departure"></i> */}
                  </div>
                  <div className="align-self-center">
                    <h6 className="text-white mt-2 mb-0">Inbound Flights</h6>
                    <h1 className="mt-0 text-white font-weight-bold">
                      {appHelpers.numberWithCommasOnly(inboundFlights)}
                    </h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm={4} md={4} lg={4}>
            <Card
              style={{
                background: "#204383",
                borderColor: "#204383",
                borderRadius: "15px",
                color: "#fff",
                marginBottom: "10px"
              }}
            >
              <span className="lstick widget-card bg-white"></span>
              <CardBody>
                <div className="d-flex">
                  <div className="mr-3 align-self-center">
                    <img
                      src={OutboundFlightIcon}
                      style={{ height: "100px" }}
                      alt="outbound flight"
                    />
                  </div>
                  <div className="align-self-center">
                    <h6 className="text-white mt-2 mb-0">Outbound Flights</h6>
                    <h1 className="mt-0 text-white font-weight-bold">
                      {appHelpers.numberWithCommasOnly(outBoundFlights)}
                    </h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm={4} md={4} lg={4}>
            <Card
              style={{
                background: "#2f2f2f",
                borderColor: "#2f2f2f",
                borderRadius: "15px",
                color: "#fff",
                marginBottom: "10px"
              }}
            >
              <span className="lstick widget-card bg-white"></span>
              <CardBody>
                <div className="d-flex">
                  <div className="mr-3 align-self-center">
                    <img
                      style={{ height: "100px" }}
                      src={NigeriaMapIcon}
                      alt="nigeria map"
                    />
                  </div>
                  <div className="align-self-center">
                    <h6 className="text-white mt-2 mb-0">Total Flights</h6>
                    <h1 className="mt-0 text-white font-weight-bold">
                      {appHelpers.numberWithCommasOnly(totalFlights)}
                    </h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card className="shadow">
              <CardBody style={{ padding: "1rem" }}>
                <FlightsMap
                  chartId="map-div"
                  flightsData={mapData}
                  airportsData={this.props.airports}
                  handleMarkerClick={this.handleMarkerClick}
                />
              </CardBody>
            </Card>

            {showDetailsCard && (
              <FlightOverlayCard
                requestingDetails={isRequestingDetails}
                selectedFlightDetails={selectedFlightDetails}
                closeFlightCard={this.closeFlightCard}
              />
            )}
          </Col>

          {/* {selectedFlightDetails !== null && ( */}
          <BottomCard
            title="View Today's Flight Schedules"
            cardName={"bottom-card"}
          >
            <FlightsScheduleTable
              flightsScheduleData={flightsScheduleData}
              isRequestingSchedule={isRequestingSchedule}
            />
          </BottomCard>
          {/* <FlightScheduleCard
            flightsScheduleData={flightsScheduleData}
            toggleScheduleCard={this.toggleFlightScheduleCard}
          /> */}
          {/* )} */}
        </Row>

        {/* <Row>
          <Col sm={12} lg={7}>
            <Card>
              <CardBody>
                <div className={"chart-wrapper"}>
                  <FlightsTable flightsData={todaysFlights} />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} lg={5}>
            <Card>
              <CardBody>
                <CardTitle style={{ color: "#bfbfbf" }}>Statistics</CardTitle>
                <div className="chart-wrapper">
               
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  // const { user } = state.auth;
  const { airlines, airports } = state;
  return {
    airlines,
    airports
  };
}

export default connect(mapStateToProps)(Flights);
