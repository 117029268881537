import React from "react";
import indexRoutes from "./routes/";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
// import { configureStore } from "./redux/store";
import { history } from "./jwt/_helpers";
import { PrivateRoute } from "./routes/PrivateRoutes";
import Blanklayout from "./layouts/blanklayout";
import { persist, store } from "./_store";
import { PersistGate } from 'redux-persist/lib/integration/react';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }

  render() {
    return (
      <Provider store={store}>
         <ReactNotification />
        <PersistGate loading={null} persistor={persist}>
          <Router basename="/" history={history}>
            <Switch>
              <Route path="/authentication/login" component={Blanklayout} />;
              {indexRoutes.map((prop, key) => {
                return (
                  <PrivateRoute
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              })}
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
