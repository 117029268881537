import React from "react";
import { Card, CardBody,CardTitle, Row, Col } from "reactstrap";
import Chart from "react-apexcharts";
import { RadialChartOptions} from "../../Charts";
import {appHelpers} from "../../_helpers";

const InvoiceChart = ({invoicesTodayCount, invoicesTodayCountPercent, invoiceValue,
                          invoiceMonthPercent, invoicesYearToDate, totalYearCountPercent,
                          monthName, title, year, location
                      }) => {
    return (
        <div>
            <Row>
                <Col md={12}>
                    <Card className={"chart-card"}>
                        <CardBody className={"card-bg radial-chart-body"} >
                            <CardTitle>{title}</CardTitle>
                            <Row>
                                <Col md={4} >
                                    <div className={"radial-chart-wrapper"}>
                                        <div>
                                            <h6 className={"text-center font-weight-bold "}>Invoice Count</h6>
                                        </div>
                                        <Chart
                                            options={RadialChartOptions(appHelpers.formatLargeNumbersWithDecimals(invoicesTodayCount), ["#0b6623"], "#0b6623", "#0b6623")}
                                            series={[invoicesTodayCountPercent]}
                                            type="radialBar"
                                            height="100%"
                                        />
                                        <div className={"marquee text-below"}>
                                            <p>{location}</p>
                                            {/*<marquee behavoir="scroll" direction="left"><h6 className={"text-center font-weight-bold"}>{location}</h6></marquee>*/}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} >
                                    <div className={"radial-chart-wrapper"}>
                                        <div>
                                            <h6 className={"text-center font-weight-bold text-wrap"}>Invoiced Value This Month</h6>
                                        </div>
                                        <Chart
                                            options={RadialChartOptions(`${appHelpers.formatLargeNumbersWithDecimals(invoiceValue)}`, ["#c64343"], "#c64343", "#0b6623")}
                                            series={[invoiceMonthPercent]}
                                            type="radialBar"
                                            height="100%"
                                        />
                                        <div className={"text-below"}>
                                            <h6 className={"text-center font-weight-bold"}>{monthName}</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} >
                                    <div className={"radial-chart-wrapper"}>
                                        <div>
                                            <h6 className={"text-center font-weight-bold"}>Invoiced Value Year to Date</h6>
                                        </div>
                                        <Chart
                                            options={RadialChartOptions(appHelpers.formatLargeNumbersWithDecimals(invoicesYearToDate), ["#4c708f"], "#4c708f", "#0b6623", )}
                                            series={[totalYearCountPercent]}
                                            type="radialBar"
                                            height="100%"
                                        />
                                        <div className={"text-below"}>
                                            <h6 className={"text-center font-weight-bold"}>{year}</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default InvoiceChart;