import {appHelpers} from "../_helpers";

export const RadialChartOptions = (total, progressColor, valueColor, totalColor, percentage) => {
  const options = {
        optionsradialBar: {
            colors: progressColor,
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: "50%",
                        background: "#ffe"
                    },
                    title: {
                        text: "Chart",
                        align: 'left',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: false,
                        style: {
                            fontSize:  '16px',
                            color:  '#263238'
                        },
                    },
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '18px',
                            color: valueColor
                        },
                        total: {
                            show: true,
                            label: total,
                            color: totalColor,
                            formatter: function(w) {
                                return percentage
                            }
                        }
                    }
                },
            }
        },
    };
    return options.optionsradialBar
};

export const AreaChartOptions = (areaColor, sign, ytitle, xtitle) => {
    const options = {
         optionsarea: {
        chart: {
            id: "area-chart",
                zoom: {
                enabled: true
            },
        },
        dataLabels: {
            enabled: false,
        },
             tooltip: {
                 enabled: true,
                 y: {
                     show: true,
                     formatter: (value) => sign + appHelpers.numberWithCommasOnly(value),

                 },
             },
        stroke: {
            width: '3',
                curve: 'smooth'
        },
        colors: areaColor,
        //     tooltip: {
        //     fillSeriesColor: false,
        // },
        xaxis: {
            title: {
                text: xtitle,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '16px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    cssClass: 'apexcharts-xaxis-title',
                },
            },
        },
        yaxis: {
            opposite: false,
            //     labels: {
            //     show: true
            // },
            labels: {
                formatter: function (value) {
                    return appHelpers.numberWithCommasOnly(value);
                }
            },
            title: {
                text: ytitle,
                rotate: 90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '16px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    cssClass: 'apexcharts-yaxis-title',
                    fontWeight: 'bold'
                },
            },
        },
        legend: {
            show: true,
                position: 'bottom',
                width: '50px',
                fontFamily: "'Montserrat', sans-serif"
        },
             responsive: [
                 {
                     breakpoint: 1024,
                     options: {
                         chart: {
                             id: "area-chart",
                             zoom: {
                                 enabled: true
                             },


                         },
                         plotOptions: {
                             bar: {
                                 horizontal: false
                             }
                         },
                         legend: {
                             position: "bottom"
                         }
                     }
                 }
             ]
    },
    };
    return options.optionsarea;
};


export const BarChartOptions = (chartColor, gradientColors, categories, horizontal, title, ytitle, sign) => {
    const options = {
        optionsbar: {
            colors: chartColor,
            chart: {
                width: '50%'
            },
            // fill: {
            //     type: 'gradient',
            //     gradient: {
            //         shade: 'light',
            //         type: "horizontal",
            //         shadeIntensity: 0.5,
            //         gradientToColors: gradientColors, // optional, if not defined - uses the shades of same color in series
            //         inverseColors: true,
            //         opacityFrom: 0.8,
            //         opacityTo: 0.8,
            //         stops: [0, 50, 100],
            //         colorStops: []
            //     }
            // },
            dataLabels: {
                enabled: false
            },
            tooltip: {
               enabled: true,
                y: {
                    show: true,
                        formatter: (value) => sign + appHelpers.numberWithCommasOnly(value),

                },
            },
            yaxis: {
                show: true,
                showAlways: true,
                title: {
                    text: ytitle,
                    rotate: 90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        cssClass: 'apexcharts-yaxis-title',
                        fontWeight: 'bold'
                    },
                },
                labels: {
                    align: 'right',
                }
                // tooltip: {
                //     enabled: true,
                //     offsetX: 0,
                // },

            },
            xaxis: {
                categories: categories,
                labels: {
                    formatter: function (value) {
                        return appHelpers.numberWithCommasOnly(value);
                    }
                },

                // labels: {
                //     show: true,
                //     rotate: -45,
                //     rotateAlways: false,
                //     hideOverlappingLabels: true,
                //     showDuplicates: false,
                //     trim: true,
                //     minHeight: undefined,
                //     maxHeight: 120,
                // },
                title: {
                    text: title,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '16px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        cssClass: 'apexcharts-xaxis-title',
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: horizontal,
                }

            }
            },
    };
    return options.optionsbar;
};

export const TotalBarChart = () => {
    const options = {
         optionTotalBar : {
             colors: ['#745af2', '#263238', '#4fc3f7'],
             plotOptions: {
                 bar: {
                     horizontal: false,
                     endingShape: 'rounded',
                     columnWidth: '55%',
                 },
             },
             dataLabels: {
                 enabled: false
             },
             stroke: {
                 show: true,
                 width: 2,
                 colors: ['transparent']
             },
             // // xaxis: {
             //     categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
             // // },
             yaxis: {
                 title: {
                     text: '$ (thousands)'
                 }
             },
             fill: {
                 opacity: 1
             },
             tooltip: {
                 y: {
                     formatter: function(val) {
                         return "$ " + val + " thousands"
                     }
                 }
             },
             legend: {
                 show: true,
                 position: 'bottom',
                 width: '50px',
                 fontFamily: "'Montserrat', sans-serif"
             },
        }
    }
    return options.optionTotalBar
}