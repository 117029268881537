export const appConstants = {
  APP_USER_KEY: "psc-user",
  APP_USER_SESSION_EXPIRED: "avi-user-session-expired",
  APP_USER_SESSION_ERROR: "avi-user-session-error",
  APP_USER_SESSION_UNAUTHORISED_ERROR_MESSAGE:
    "You do not have the permission to access page!",
  APP_USER_SESSION_UNAUTHORISED_ERROR_MESSAGE_2:
    "You have not been provision to access this system",
  APP_USER_SESSION_EXPIRED_MESSAGE: "Your session has expired!",
  APP_USER_SESSION_AUTHENTICATION_REQUIRED_MESSAGE:
    "You need to signin to access page!",
  APP_INTERNET_CONNECTION_MESSAGE: "Please Check your internet connectivity",
  INACTIVITY_EXPIRATION_LIMIT: 3600,

  //API URLS
  // PAS_URL: (process.env.NODE_ENV === 'development')? 'https://passerelle.test.vggdev.com' : window.env.passerelleUrl,
  PAS_URL:
    process.env.NODE_ENV === "development"
      ? "https://passerelle.test.vggdev.com"
      : window.env.passerelleUrl,
  ENV_ID: process.env.NODE_ENV === "development" ? "test" : window.env.envId,
  APP_ID: process.env.NODE_ENV === "development" ? "PSC Web" : window.env.appId,
  FORGOT_PASSWORD_URL:
    process.env.NODE_ENV === "development"
      ? "https://usermanager.test.vggdev.com/Account/ForgotPassword"
      : window.env.forgotPasswordUrl,
  MAKER_CHECKER_APP_ID:
    process.env.NODE_ENV === "development" ? 7 : window.env.makerCheckerAppId,
  APP_URL: "http://charter-api.test.vggdev.com",

  LAGOS_IN_CCTV_URL:
    process.env.NODE_ENV === "development"
      ? "https://www.cameraftp.com/Camera/Cameraplayer.aspx?parentID=301414125&shareID=14598834&isEmbedded=true&mode=live"
      : window.env.lagosInCCTVUrl,
  LAGOS_OUT_CCTV_URL:
    process.env.NODE_ENV === "development"
      ? "https://www.cameraftp.com/Camera/Cameraplayer.aspx?parentID=301414125&shareID=14598834&isEmbedded=true&mode=live"
      : window.env.lagosOutCCTVUrl,
  ABUJA_CCTV_URL:
    process.env.NODE_ENV === "development"
      ? "https://www.cameraftp.com/Camera/Cameraplayer.aspx?parentID=302108798&shareID=14616985&isEmbedded=true&mode=live"
      : window.env.abujaCCTVUrl,
      STREAM_URL:  process.env.NODE_ENV === "development"
      ? "ws://adsbdatastreamer.staging.vggdev.com:8181/key="
      : window.env.streamUrl,
      STREAM_KEY:  process.env.NODE_ENV === "development"
      ? "F1873F90B92E2AA612323AE689EF7EF6214F93D8A8AE61E67E9761E8BDC6AA27FDF2418BF9D8E3320439A1167C764A260101CDAA7341AAD583CC3CDDFC60807F"
      : window.env.streamKey,

  //Action Types
  ADD_LOG: "add_log",
  OPEN_MODAL: "open_modal",
  CLOSE_MODAL: "close_modal",
  CLEAR_MODAL: "clear_modal",
  //API Status
  API_REQUEST_START: "API_REQUEST_START",
  API_REQUEST_FULL_PAGE_START: "API_REQUEST_FULL_PAGE_START",
  API_REQUEST_FINISH: "API_REQUEST_FINISH",
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  REQUEST_FAILURE: "REQUEST_FAILURE",
  GET_REQUEST: "get",
  POST_REQUEST: "post",

  NOTIFY_SUCCESS: "success",
  NOTIFY_ERROR: "error",
  NOTIFY_INFO: "info",
  NOTIFY_INVALID: "in",
  NOTIFY_WARNING: "warning",
  NOTIFY_DANGER: "danger",

  SUCCESS_RESPONSE: 1,
  ERROR_RESPONSE: 2,

  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",

  /*Colors*/
  PRIMARY_COLOR: "#2c0e73",
  INFO_COLOR: "#00acc1",
  WARNING_COLOR: "#fb8c00",
  MUTED_COLOR: "#3b3b3b",
  DANGER_COLOR: "#f44336",

  /*user Roles*/
  ROLES: {
    SUPER_ADMIN: "Super Admin"
  },

  //actions
  ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  USERS_SUCCESS: "GET_USERS_SUCCESS",
  AIRLINES_SUCCESS: "GET_AIRLINES_SUCCESS",
  AIRPORTS_SUCCESS: "GET_AIRPORTS_SUCCESS",
  SIGN_IN_SUCCESS: "POST_SIGN_IN_SUCCESS",
  SIGN_OUT: "SIGN_OUT",
  ADD_DASHBOARD: "ADD_DASHBOARD",

  PDF_DOWNLOAD_TYPE: 1,
  EXCEL_DOWNLOAD_TYPE: 0,

  CLEAR_AIRPORTS: "CLEAR_AIRPORTS",
  CLEAR_AIRLINES: "CLEAR_AIRLINES",
  SET_HEADER_TITLE: "SET_HEADER_TITLE"
};
