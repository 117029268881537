import React from "react";
import { ProgressBarProvider } from "react-redux-progress";

export const ProgressBar = ({ progress }) => (
  <ProgressBarProvider
    isActive={progress}
    color="#4D7F2C"
    styles={{ height: "4px" }}
  />
);
