import { combineReducers } from "redux";

// reducers
import settings from "./../redux/settings/reducer";
import { airports } from "./airports.reducer";
import { airlines } from "./airlines.reducer";
import { activity } from "./activity.reducer";
import { requesting } from "./requesting.reducer";
import { user } from "./user.reducer";
import { headerTitle } from "./headerTitle.reducer";

const appReducers = combineReducers({
  settings,
  airports,
  airlines,
  activity,
  requesting,
  user,
  headerTitle
});

export default appReducers;
