import moment from "moment/moment";
import { appConstants } from "../_constants";
import axios from "axios";
import { store } from "../_store";
import { history } from "../_helpers";

export const appHelpers = {
  formatDate: d => {
    if (!d) return "";
    return moment(d.split("T")[0]).format("MMM DD, YYYY");
  },
  formatDateSpecific: (d, format) => {
    return moment(d).format(format || "MMM DD, YYYY");
  },
  getRequest: function(url) {
    const { user } = store.getState();

    let combinedHeader = {
      "Content-Type": "application/json",
      AppName: appConstants.APP_ID
    };
    if (user) {
      combinedHeader.Authorization = `Bearer ${user.access_token}`;
    }
    let config = { headers: combinedHeader };

    // let token = `RKE9CUk8PelANCkSFg3vG0fS7gQ_g6z2DL1HZZGvfQEu6BcCs9fUjo25DoI_jApJhwVDTHR-cRSNNjvegshJNmrSEBslCEauL3nnB2vPSeSR2S2HOrjw1HQhc03VLMUreCFGPTOduqz9Nsn2zuNtzUMujQuOQ9JVQKYW5Jyco10JnUnFxDK6lk6KLnaxC_fhBBGjGbJVyis_s5wo0h9O4yrEGH-RuSzDcF_UzWAviFXnThDAtivhxkBB9bpG4_sF2HXhHDUB07z-f6vvGdtgviDqmaNAZSAwdfIRoXzd5ddsOD5fd5tvyTTc89K5YEAZE5MYIwqSL06ww4Te6xL_3jHsL4yT1TK6M-RtofNv9mz3le2Vod4EcOpYKmY3zkCSBe9EVYJAfWmOraUWqFYEdGRUOKBNjNd8Voc5TQcLR7bjnZv3Xa8Vuyxi8ufKqlPRer1YYu2iA9DMlpdn8m_x8eDuVLgVPUwqv49DPnUcg7eg_O1d4i5imYnHpJ7e6ahEgbvvWtWWXAnN14rKsgD87rbIZZlQUMHjRZBr2D1PWORjaZhc07aEqhVbo1hwE5dS3VhybA`;
    // let combinedHeaders = {
    //   "Content-Type": "application/json",
    //   Authorization: `Bearer ${token}`
    // };
    // let config = { headers: combinedHeaders };
    return axios
      .get(url, config)
      .then(function(res) {
        return appHelpers.promiseResponse(res.data);
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          let msg = error.response.data;
          if (error.response.status === 500) {
            msg = "Oops, something went wrong";
          } else if (error.response.status === 404) {
            msg = "Resource not found";
          } else if (error.response.status === 401) {
            msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
            setTimeout(() => {
              history.push("/authentication/login");
            }, 3000);
          }
          return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
          // return {statTs: appConstants.REQUEST_FAILURE, data: error.response.data};
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
          // return {status: appConstants.REQUEST_FAILURE, data: error.request};
          const errorMessage =
            error.request.status === 0
              ? appConstants.APP_INTERNET_CONNECTION_MESSAGE
              : error.request;

          return appHelpers.promiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          // return {status: appConstants.REQUEST_FAILURE, data: error.message};
          return appHelpers.promiseResponse(
            error.message,
            appConstants.ERROR_RESPONSE
          );
        }
      });
  },
  postRequest: function(url, payload, addedHeader = null) {
    const { user } = store.getState();
    let combinedHeader = {
      "Content-Type": "application/json",
      AppName: appConstants.APP_ID
    };

    if (user) {
      combinedHeader.Authorization = `Bearer ${user.access_token}`;
      // combinedHeader.Location = user.Location;
    }
    if (addedHeader) {
      combinedHeader = { ...combinedHeader, ...addedHeader };
    }

    let config = { headers: combinedHeader };

    return axios
      .post(url, payload, config)
      .then(res => {
        return appHelpers.promiseResponse(res.data);
      })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          let msg = error.response.data;
          if (error.response.status === 500) {
            msg = "Oops, something went wrong";
          } else if (error.response.status === 404) {
            msg = "Resource not found";
          } else if (error.response.status === 401) {
            msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
            setTimeout(() => {
              history.push("/authentication/login");
            }, 3000);
          }
          return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
          // return {statTs: appConstants.REQUEST_FAILURE, data: error.response.data};
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
          // return {status: appConstants.REQUEST_FAILURE, data: error.request};
          const errorMessage =
            error.request.status === 0
              ? appConstants.APP_INTERNET_CONNECTION_MESSAGE
              : error.request;

          return appHelpers.promiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          // return {status: appConstants.REQUEST_FAILURE, data: error.message};
          return appHelpers.promiseResponse(
            error.message,
            appConstants.ERROR_RESPONSE
          );
        }
      });
  },
  formatPromiseResponse: function(res, resType) {
    let responseType =
      resType === undefined ? appConstants.SUCCESS_RESPONSE : resType;
    return { status: responseType, response: res };
  },
  promiseResponse: function(res, resType) {
    let responseType =
      resType === undefined ? appConstants.SUCCESS_RESPONSE : resType;
    return { status: responseType, response: res };
  },
  interpretErrorResponse(error) {
    let errorMessage = "";
    if (error.response === undefined) {
      errorMessage = "Please check your internet connectivity!";
    } else {
      errorMessage = error.response.data
        ? error.response.data
        : "Unable to handle request";
    }
    if (typeof errorMessage === "string") {
      return errorMessage;
    } else {
      return "Something went wrong!";
    }
  },
  numberWithCommas: x => {
    x = x.toFixed(2);
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  },
  numberWithCommasOnly: x => {
    if (x === 0) return "0";
    if (!x) return "";
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  },
  formatLargeNumbersWithDecimals: labelValue => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + "K"
      : Math.abs(Number(labelValue));
  },
  formatLargeNumbers: labelValue => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? Math.abs(Number(labelValue)) + "K"
      : Math.abs(Number(labelValue));
  },
  fetchDayId: day => {
    switch (day) {
      case "Sunday":
        return 1;
      case "Monday":
        return 2;
      case "Tuesday":
        return 3;
      case "Wednesday":
        return 4;
      case "Thursday":
        return 5;
      case "Friday":
        return 6;
      case "Saturday":
        return 7;
      default:
        return 1;
    }
  },
  destroySession: function() {
    let __usr__ = localStorage.getItem(appConstants.AVI_USER_KEY);
    if (__usr__) localStorage.removeItem(appConstants.AVI_USER_KEY);
  },
  interpretAirport: function(airportId) {
    const { airports } = store.getState();

    let airport = airports.find(item => item.Id === airportId);
    return airport ? airport.Name : "Not available";
  },
  interpretAirline: function(airlineId, airlineName = null) {
    if (appConstants.ENV_ID === "production") {
      switch (airlineId) {
        case 22:
          return "aero.jpg";
        case 1157:
          return "airpeace.jpg";
        case 21:
          return "arik.png";
        case 130:
          return "azman.png";
        case 35:
          return "dana.png";
        case 1167:
          return "ibomair.png";
        case 1161:
          return "maxair.jpg";
        case 34:
          return "medview.jpg";
        case 127:
          return "overland.jpg";
          //International
        case 2168:
          return "cronosairlines.jpg";
        case 1159:
          return "tarcoairlines-logo.jpg";
        case 1158:
          return "badrairlines.jpeg";
        case 18:
          return "klmairlines.jpg";
        case 13:
          return "askyairlines.jpg";
        case 1:
          return "middleeastairlines.jpg";
        case 77:
          return "airitaly.png";
        case 24:
          return "etihad.png";
        case 23:
          return "kenyaairways.png";
        case 20:
          return "southafricanairways.png";
        case 17:
          return "airfrance.png";
        case 16:
          return "ethopian.png";
        case 15:
          return "rwandair.jpg";
        case 12:
          return "virginatlantic.png";
        case 10:
          return "royalairmaroc.png";
        case 9:
          return "egyptair.png";
        case 8:
          return "turkishairlines.png";
        case 7:
          return "emirates.png";
        case 5:
          return "britishairways.png";
        case 4:
          return "delta.png";
        case 3:
          return "qatar.png";
        case 2:
          return "lufthansa.png";
        default:
          return airlineName ? airlineName : "airplane_placeholder.png";
      }
    } else {
      switch (airlineId) {
        case 22:
          return "aero.jpg";
        case 64:
          return "airpeace.jpg";
        case 21:
          return "arik.png";
        case 4110:
          return "azman.png";
        case 60:
          return "dana.png";
        case 4116:
          return "maxair.jpg";
        case 34:
          return "medview.jpg";
        case 72:
          return "overland.jpg";
          //International
        case 2168:
          return "cronosairlines.jpg";
        case 1159:
          return "tarcoairlines-logo.jpg";
        case 1158:
          return "badrairlines.jpeg";
        case 18:
          return "klmairlines.jpg";
        case 13:
          return "askyairlines.jpg";
        case 1:
          return "middleeastairlines.jpg";
        case 77:
          return "airitaly.png";
        case 24:
          return "etihad.png";
        case 23:
          return "kenyaairways.png";
        case 20:
          return "southafricanairways.png";
        case 17:
          return "airfrance.png";
        case 16:
          return "ethopian.png";
        case 15:
          return "rwandair.jpg";
        case 12:
          return "virginatlantic.png";
        case 10:
          return "royalairmaroc.png";
        case 9:
          return "egyptair.png";
        case 8:
          return "turkishairlines.png";
        case 7:
          return "emirates.png";
        case 5:
          return "britishairways.png";
        case 4:
          return "delta.png";
        case 3:
          return "qatar.png";
        case 4109:
          return "lufthansa.png";
        default:
          return airlineName ? airlineName : "airplane_placeholder.png";
      }
    }
  }
};
