import * as am4core from "@amcharts/amcharts4/core";

function progressBars(summary, colors, hasTotal = false) {
    let progressBars = [];
    let summaryKeys = Object.keys(summary);
    if (hasTotal)
        summaryKeys.pop();

    let total = 0;
    summaryKeys.forEach((item) => {
        total += summary[item];
    });

    summaryKeys.forEach((item) => {
        progressBars.push({
            name: item,
            value: summary[item],
            variant: colors[item],
            percent: (total > 0) ?
                (summary[item] / total).toFixed(2) * 100 :
                0
        });
    });
    return { progressBars, total };
}


function chartData(response, type){
    let data = [];

    if (type === 'count') {
        data = response.map(item => item.Count)
    }
    else if (type === 'amount') {
        data = response.map(item => item.Amount)
    }
    else if (type === 'location') {
        data = response.map(item => item.Location)
    }
    else if (type === 'customer') {
        data = response.map(item => item.CustomerName)
    }
    else if (type === 'passengerCount') {
        data = response.map(item => item.PassengerCount)
    }
    return data
}

// function chartData(data, duration = 0) {
//     const format = (duration < 1) ? "H:mm" : "MMM DD, H:mm";
//     let data_ = [];
//     data.forEach((item) => {
//         let item_ = { ...item };
//         item_.name = appHelpers.formatDateSpecific(item.name, format);
//         data_.push(item_)
//     });
//     return data_;
// }

function addXAxisNames(data, key) {
    let data_ = [...data];
    data_.map((item) => {
        item.name = item[key]
        return item
    })
    return data_;
}

function generateSummaryData(data) {
    let keys = Object.keys(data);
    const data_ = keys.map((item, i) => {
        return {
            name: item,
            value: data[item]
        }
    })
    return data_;
}

function renamePropertyKeys(data, oldKey, newKey) {
    let data_ = [...data];
    data_.map((item) => {
        item[newKey] = item[oldKey];
        delete item[oldKey];
        return item;
    });
    return data_;
}

function getCategories(response) {
    let categories = [];

    let sortedMonth =  response.sort((a, b) => {
        return (a.Month) - (b.Month)
    });


    sortedMonth.forEach(item => {
        let monthInt = item.Month;
        switch(true){
            case (monthInt === 1):
                categories.push("Jan");
                break;
            case (monthInt === 2):
                categories.push("Feb");
                break;
            case (monthInt === 3):
                categories.push("Mar");
                break;
            case (monthInt === 4):
                categories.push("Apr");
                break;
            case (monthInt === 5):
                categories.push("May");
                break;
            case (monthInt === 6):
                categories.push("Jun");
                break;
            case (monthInt === 7):
                categories.push("Jul");
                break;
            case (monthInt === 8):
                categories.push("Aug");
                break;
            case (monthInt === 9):
                categories.push("Sep");
                break;
            case (monthInt === 10):
                categories.push("Oct");
                break;
            case (monthInt === 11):
                categories.push("Nov");
                break;
            case (monthInt === 12):
                categories.push("Dec");
                break;
            default:
        }

    })

    return categories;
}

function getTotalCategories(response, response2) {
    let categories = [];

    response.forEach(item => {
        let monthInt = item.Month;
         switch(true){
            case (monthInt === 1):
                categories.push("Jan");
                break;
            case (monthInt === 2):
                categories.push("Feb");
                break;
            case (monthInt === 3):
                categories.push("Mar");
                break;
            case (monthInt === 4):
                categories.push("Apr");
                break;
            case (monthInt === 5):
                categories.push("May");
                break;
            case (monthInt === 6):
                categories.push("Jun");
                break;
            case (monthInt === 7):
                categories.push("Jul");
                break;
            case (monthInt === 8):
                categories.push("Aug");
                break;
            case (monthInt === 9):
                categories.push("Sep");
                break;
            case (monthInt === 10):
                categories.push("Oct");
                break;
            case (monthInt === 11):
                categories.push("Nov");
                break;
            case (monthInt === 12):
                categories.push("Dec");
                break;
            default:
        }

    });
    response2.forEach(item => {
        let monthInt = item.Month;
        switch(true){
            case (monthInt === 1):
                if( categories.indexOf("Jan") === -1){
                    categories.push("Jan");
                }
                break;
            case (monthInt === 2):
                if( categories.indexOf("Feb") === -1){
                    categories.push("Feb");
                }
                break;
            case (monthInt === 3):
                if( categories.indexOf("Mar") === -1){
                    categories.push("Mar");
                }
                break;
            case (monthInt === 4):
                if( categories.indexOf("Apr") === -1){
                    categories.push("Apr");
                }
                break;
            case (monthInt === 5):
                if( categories.indexOf("May") === -1){
                    categories.push("May");
                }
                break;
            case (monthInt === 6):
                if( categories.indexOf("Jun") === -1){
                    categories.push("Jun");
                }
                break;
            case (monthInt === 7):
                if( categories.indexOf("Jul") === -1) {
                    categories.push("Jul");
                }
                break;
            case (monthInt === 8):
                if( categories.indexOf("Aug") === -1) {
                    categories.push("Aug");
                }
                break;
            case (monthInt === 9):
                if( categories.indexOf("Sep") === -1) {
                    categories.push("Sep");
                }
                break;
            case (monthInt === 10):
                if( categories.indexOf("Oct") === -1){
                    categories.push("Oct");
                }
                break;
            case (monthInt === 11):
                if( categories.indexOf("Nov") === -1){
                    categories.push("Nov");
                }
                break;
            case (monthInt === 12):
                if( categories.indexOf("Dec") === -1){
                    categories.push("Dec");
                }
                break;
            default:
        }

    })

    return categories;
}

function seriesData(response, type){
    let spreadResponse = [...response];

    let sortedMonth =  spreadResponse.sort((a, b) => {
        return (a.Month) - (b.Month)
    });

    const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    let data = sortedMonth.map(item => {
        let months = monthNames[item.Month - 1];
        let value;
        if (type === "count") {
            value = ({x: `${months} ${item.Year}` , y: item.Count})
        } else if (type === "amount") {
            value = ({x: `${months} ${item.Year}`, y: item.Amount})
        }

        return value;
    })

    // let data2 = data.map(item => {
    //     switch(true){
    //         case (item.x.includes(1) ):
    //             item.x = "Jan"
    //             break;
    //         case (item.x === 2):
    //             item.x = "Feb";
    //             break;
    //         case (item.x === 3):
    //             item.x = "Mar";
    //             break;
    //         case (item.x === 4):
    //             item.x = "Apr";
    //             break;
    //         case (item.x === 5):
    //             item.x = "May";
    //             break;
    //         case (item.x === 6):
    //             item.x = "Jun";
    //             break;
    //         case (item.x === 7):
    //             item.x = "Jul";
    //             break;
    //         case (item.x === 8):
    //             item.x = "Aug";
    //             break;
    //         case (item.x === 9):
    //             item.x = "Sep";
    //             break;
    //         case (item.x === 10):
    //             item.x = "Oct";
    //             break;
    //         case (item.x === 11):
    //             item.x = "Nov";
    //             break;
    //         case (item.x === 12):
    //             item.x = "Dec"
    //             break;
    //         default:
    //     }
    //     return item;
    //
    // });

    return data;
}

function renamePassengerMapData(response) {
    const resultArray = response.map(item =>
        ({ AirportCode: item.AirportCode, AirportName: item.AirportName, id: item.StateCode,
            name: item.StateName, Latitude: item.Latitude, Longitude: item.Longitude,
            value: item.OutboundPassengerCount, fill: am4core.color("#c42c21")}));
    return resultArray;
}

export const graphHelpers = {
    progressBars,
    // chartData,
    addXAxisNames,
    generateSummaryData,
    renamePropertyKeys,
    chartData,
    getCategories,
    getTotalCategories,
    seriesData,
    renamePassengerMapData
}