import React from "react";
import "./index.css";
// import Chart from "react-apexcharts";
// import {AreaChartOptions, BarChartOptions, RadialChartOptions} from "../../Charts";
import { commonService } from "../../_services";
import { appConstants } from "../../_constants";
import moment from "moment";
import { connect } from "react-redux";
import { alertActions, commonActions } from "../../_actions";
// import {appHelpers} from "../../_helpers";
import { graphHelpers } from "../../_helpers/graph.helpers";
import { store } from "react-notifications-component";
import AgencyRevenue from "./agencyRevenue";

class Revenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryMetricsDate: moment(), //Production
      // summaryMetricsDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),

      // monthlyRevenueStartDate: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
      // monthlyRevenueStartDate: moment().subtract(9, 'months').format('YYYY-MM-DD'),
      monthlyRevenueStartDate: moment()
        .startOf("year")
        .format("YYYY-MM-DD"), //Production
      monthlyRevenueEndDate: moment(),
      // locationStartDate: moment().subtract(9, 'months').format('YYYY-MM-DD'),
      locationStartDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      locationEndDate: moment(),
      // customerStartDate: moment().subtract(9, 'months').format('YYYY-MM-DD'),
      customerStartDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      customerEndDate: moment(),
      AgencyId: 20,

      count: 5,
      /* Naira Invoices Value*/
      invoicesTodayNairaCount: 0,
      invoicesTodayNairaCountPercent: 0,
      invoiceNairaMonthAmount: 0,
      invoiceNairaMonthAmtPercent: 0,
      currentYearNairaInvoices: 0,
      currentYearNairaInvoicesCountPercent: 0,
      /* Dollar Invoices Value */
      invoicesTodayDollarCount: 0,
      invoicesTodayDollarCountPercent: 0,
      invoiceDollarMonthAmount: 0,
      invoiceDollarMonthAmtPercent: 0,
      totalDollarInvoices: 0,
      totalDollarMonthCountPercent: 0,
      /* Naira Revenue Amount */
      nairaMonthlyRevenueAmount: [],
      nairaMonthlyRevenueCount: [],
      nairaRevenueMonths: [],
      /* Dollar Revenue Amount */
      dollarMonthlyRevenueAmount: 0,
      dollarMonthlyRevenueCount: 0,
      dollarRevenueMonths: [],
      /* Total Revenue Months */
      totalRevenueMonths: [],
      /* Top Locations */
      nairaTopLocationsData: [],
      nairaTopLocationsCategory: [],
      /* naira Top Customers */
      nairaTopCustomersData: [],
      nairaTopCustomersCategory: [],
      /* Dollar Top Customers */
      dollarTopCustomersData: [],
      dollarTopCustomersCategory: [],
      orderType: { count: 1, amount: 2 },
      currencyId: { naira: 566, dollar: 840 },
      selectedCurrencyButton: 1,
      selectedAgencyButton: 1,
      demoValue: 1,
      response: 0,
      getMore: 0,
      monthName: "",
      year: "",
      location: "",
      dollarLocation: "",
      interval: null,
      //NCAA
      ncaaId: 10,
      ncaaSummaryMetricsDate: moment(),
      ncaaMonthlyRevenueStartDate: moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      ncaaMonthlyRevenueEndDate: moment(),
      ncaaLocationStartDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      ncaaLocationEndDate: moment(),
      ncaaCustomerEndDate: moment(),
      ncaaCustomerStartDate: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      ncaaOrderType: { count: 1, amount: 2 },
      // ncaaCurrencyId: {naira: 566, dollar: 840},
      ncaaInvoicesTodayNairaCount: 0,
      ncaaInvoicesTodayNairaCountPercent: 0,
      ncaaInvoiceNairaMonthAmount: 0,
      ncaaInvoiceNairaMonthAmtPercent: 0,
      ncaaCurrentYearNairaInvoices: 0,
      ncaaCurrentYearNairaInvoicesCountPercent: 0,
      /* Dollar Invoices Value */
      ncaaInvoicesTodayDollarCount: 0,
      ncaaInvoicesTodayDollarCountPercent: 0,
      ncaaInvoiceDollarMonthAmount: 0,
      ncaaInvoiceDollarMonthAmtPercent: 0,
      ncaaTotalDollarInvoices: 0,
      ncaaTotalDollarMonthCountPercent: 0,
      /* Naira Revenue Amount */
      ncaaNairaMonthlyRevenueAmount: [],
      ncaaNairaMonthlyRevenueCount: [],
      ncaaNairaRevenueMonths: [],
      /* Dollar Revenue Amount */
      ncaaDollarMonthlyRevenueAmount: 0,
      ncaaDollarMonthlyRevenueCount: 0,
      ncaaDollarRevenueMonths: [],
      /* Total Revenue Months */
      ncaaTotalRevenueMonths: [],
      /* Top Locations */
      ncaaNairaTopLocationsData: [],
      ncaaNairaTopLocationsCategory: [],
      /* naira Top Customers */
      ncaaNairaTopCustomersData: [],
      ncaaNairaTopCustomersCategory: [],
      /* Dollar Top Customers */
      ncaaDollarTopCustomersData: [],
      ncaaDollarTopCustomersCategory: [],
      ncaaDollarTopLocationsCategory: [],
      ncaaDollarTopLocationsData: []
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    this.fetchPageData();
    let intervalId = setInterval(() => {
      this.fetchPageData();
    }, 300000);
    this.setState({ intervalId: intervalId });

    if (window.location.pathname !== "/dashboard") {
      dispatch(commonActions.setHeaderTitle("FAAN REVENUE"));
    }
  }

  fetchPageData = () => {
    let {
      AgencyId,
      summaryMetricsDate,
      monthlyRevenueStartDate,
      monthlyRevenueEndDate,
      locationStartDate,
      locationEndDate,
      customerStartDate,
      customerEndDate,
      orderType,
      count,
      currencyId
    } = this.state;
    const { dispatch } = this.props;

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    let monthNumber = moment(summaryMetricsDate).format("M");
    let monthName = monthNames[monthNumber - 1];
    let year = moment(summaryMetricsDate).format("YYYY");
    // console.log({year})

    dispatch(alertActions.startRequest());

    const summaryMetricsPayload = {
      AgencyId: AgencyId,
      Date: summaryMetricsDate
    };
    const monthyRevenuePayload = {
      StartDate: monthlyRevenueStartDate,
      EndDate: monthlyRevenueEndDate,
      AgencyId: AgencyId
    };
    const locationPayload = {
      StartDate: locationStartDate,
      EndDate: locationEndDate,
      AgencyId,
      OrderType: orderType.count,
      Count: count,
      CurrencyId: currencyId.naira
    };
    const customerPayload = {
      StartDate: customerStartDate,
      EndDate: customerEndDate,
      AgencyId,
      OrderType: orderType.count,
      Count: count,
      CurrencyId: currencyId.naira
    };
    const dollarCustomerPayload = {
      StartDate: customerStartDate,
      EndDate: customerEndDate,
      AgencyId,
      OrderType: orderType.count,
      Count: count,
      CurrencyId: currencyId.dollar
    };
    const dollarLocationsPayload = {
      StartDate: locationStartDate,
      EndDate: locationEndDate,
      AgencyId,
      OrderType: orderType.count,
      Count: count,
      CurrencyId: currencyId.dollar
    };
    Promise.all([
      commonService.searchInvoiceSummaryMetrics(summaryMetricsPayload),
      commonService.searchMonthlyRevenueTrend(monthyRevenuePayload),
      commonService.searchTopLocations(locationPayload),
      commonService.searchTopLocations(dollarLocationsPayload),
      commonService.searchTopCustomers(customerPayload),
      commonService.searchTopCustomers(dollarCustomerPayload)
    ])
      .then(response => {
        dispatch(alertActions.stopRequest());

        // console.log(response)
        const invoiceSummaryMetrics = response[0].response.Results;
        const invoiceSummary = response[0].response;
        const monthlyRevenue = response[1].response.Result;
        const nairaLocations = response[2].response.Result;
        const dollarLocations = response[3].response.Result;
        const topCustomers = response[4].response.Result;
        const topDollarCustomers = response[5].response.Result;

        // console.log(monthlyRevenue.Naira.Result,);
        this.setState(
          {
            response: 1,
            monthName,
            year,
            //Naira Invoices
            invoicesTodayNairaCount:
              invoiceSummaryMetrics.Naira.CurrentDayCount,
            invoicesTodayNairaCountPercent:
              invoiceSummaryMetrics.Naira.CurrentDayCountPercentageMovement,
            invoiceNairaMonthAmount:
              invoiceSummaryMetrics.Naira.CurrentMonthAmount,
            invoiceNairaMonthAmtPercent:
              invoiceSummaryMetrics.Naira.CurrentMonthAmountPercentageMovement,
            currentYearNairaInvoices:
              invoiceSummaryMetrics.Naira.CurrentYearAmount,
            currentYearNairaInvoicesCountPercent:
              invoiceSummaryMetrics.Naira.CurrentYearAmountPercentageMovement,
            //Dollar Invoices
            invoicesTodayDollarCount:
              invoiceSummaryMetrics.Dollar.CurrentDayCount,
            invoicesTodayDollarCountPercent:
              invoiceSummaryMetrics.Dollar.CurrentDayCountPercentageMovement,
            invoiceDollarMonthAmount:
              invoiceSummaryMetrics.Dollar.CurrentMonthAmount,
            invoiceDollarMonthAmtPercent:
              invoiceSummaryMetrics.Dollar.CurrentMonthAmountPercentageMovement,
            totalDollarInvoices: invoiceSummaryMetrics.Dollar.CurrentYearAmount,
            totalDollarMonthCountPercent:
              invoiceSummaryMetrics.Dollar.CurrentYearAmountPercentageMovement,
            location: invoiceSummary.Summary.LastInvoice.Location,
            //Naira Monthly Revenue
            nairaMonthlyRevenueAmount: graphHelpers.seriesData(
              monthlyRevenue.Naira.Result,
              "amount"
            ),
            // nairaMonthlyRevenueCount: graphHelpers.chartData(monthlyRevenue.Naira.Result, "count"),
            nairaMonthlyRevenueCount: graphHelpers.seriesData(
              monthlyRevenue.Naira.Result,
              "count"
            ),
            nairaRevenueMonths: graphHelpers.getCategories(
              monthlyRevenue.Naira.Result
            ),
            totalRevenueMonths: graphHelpers.getTotalCategories(
              monthlyRevenue.Naira.Result,
              monthlyRevenue.Dollar.Result
            ),
            //Dollar Monthly Revenue
            dollarMonthlyRevenueAmount: graphHelpers.seriesData(
              monthlyRevenue.Dollar.Result,
              "amount"
            ),
            dollarMonthlyRevenueCount: graphHelpers.seriesData(
              monthlyRevenue.Dollar.Result,
              "count"
            ),
            dollarRevenueMonths: graphHelpers.getCategories(
              monthlyRevenue.Dollar.Result
            ),
            //Top Locations
            nairaTopLocationsData: graphHelpers.chartData(
              nairaLocations,
              "count"
            ),
            nairaTopLocationsCategory: graphHelpers.chartData(
              nairaLocations,
              "location"
            ),
            //Dollar Top Locations
            dollarTopLocationsData: graphHelpers.chartData(
              dollarLocations,
              "count"
            ),
            dollarTopLocationsCategory: graphHelpers.chartData(
              dollarLocations,
              "location"
            ),
            //Naira Top Customers
            nairaTopCustomersData: graphHelpers.chartData(
              topCustomers,
              "amount"
            ),
            nairaTopCustomersCategory: graphHelpers.chartData(
              topCustomers,
              "customer"
            ),
            //Dollar Top Customers
            dollarTopCustomersData: graphHelpers.chartData(
              topDollarCustomers,
              "amount"
            ),
            dollarTopCustomersCategory: graphHelpers.chartData(
              topDollarCustomers,
              "customer"
            )
          },
          // () =>
            // console.log("naira revenue", this.state.nairaMonthlyRevenueAmount)
        );
        if (
          response[0].status === appConstants.SUCCESS_RESPONSE &&
          response[1].status === appConstants.SUCCESS_RESPONSE &&
          response[2].status === appConstants.SUCCESS_RESPONSE &&
          response[3].status === appConstants.SUCCESS_RESPONSE
        ) {
          this.setState({
            response: 1
          });
        }
      })
      .catch(err => {
        // console.log({ err });
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(err));
        store.addNotification({
          // title: "",
          message: "Unable to process request",
          type: "danger",
          insert: "top",
          width: 250,
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000
            //   onScreen: true
          }
        });
      });

    dispatch(alertActions.startRequest());
  };

  //NCAA
  fetchNCAAPageData = () => {
    let {
      ncaaId,
      ncaaSummaryMetricsDate,
      ncaaMonthlyRevenueStartDate,
      ncaaMonthlyRevenueEndDate,
      ncaaLocationStartDate,
      ncaaLocationEndDate,
      ncaaCustomerEndDate,
      ncaaCustomerStartDate,
      ncaaOrderType,
      count,
      currencyId
    } = this.state;
    const { dispatch } = this.props;

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    let monthNumber = moment(ncaaSummaryMetricsDate).format("M");
    let monthName = monthNames[monthNumber - 1];
    let year = moment(ncaaSummaryMetricsDate).format("YYYY");
    // console.log({year})

    dispatch(alertActions.startRequest());

    const ncaaSummaryMetricsPayload = {
      AgencyId: ncaaId,
      Date: ncaaSummaryMetricsDate
    };
    const ncaaMonthyRevenuePayload = {
      StartDate: ncaaMonthlyRevenueStartDate,
      EndDate: ncaaMonthlyRevenueEndDate,
      AgencyId: ncaaId
    };
    const ncaaLocationPayload = {
      StartDate: ncaaLocationStartDate,
      EndDate: ncaaLocationEndDate,
      AgencyId: ncaaId,
      OrderType: ncaaOrderType.count,
      Count: count,
      CurrencyId: currencyId.naira
    };
    const ncaaCustomerPayload = {
      StartDate: ncaaCustomerStartDate,
      EndDate: ncaaCustomerEndDate,
      AgencyId: ncaaId,
      OrderType: ncaaOrderType.count,
      Count: count,
      CurrencyId: currencyId.naira
    };
    const ncaaDollarCustomerPayload = {
      StartDate: ncaaCustomerStartDate,
      EndDate: ncaaCustomerEndDate,
      AgencyId: ncaaId,
      OrderType: ncaaOrderType.count,
      Count: count,
      CurrencyId: currencyId.dollar
    };
    const ncaaDollarLocationsPayload = {
      StartDate: ncaaLocationStartDate,
      EndDate: ncaaLocationEndDate,
      AgencyId: ncaaId,
      OrderType: ncaaOrderType.count,
      Count: count,
      CurrencyId: currencyId.dollar
    };
    Promise.all([
      commonService.searchInvoiceSummaryMetrics(ncaaSummaryMetricsPayload),
      commonService.searchMonthlyRevenueTrend(ncaaMonthyRevenuePayload),
      commonService.searchTopLocations(ncaaLocationPayload),
      commonService.searchTopLocations(ncaaDollarLocationsPayload),
      commonService.searchTopCustomers(ncaaCustomerPayload),
      commonService.searchTopCustomers(ncaaDollarCustomerPayload)
    ])
      .then(response => {
        dispatch(alertActions.stopRequest());

        // console.log(response)
        const ncaaInvoiceSummaryMetrics = response[0].response.Results;
        const ncaaInvoiceSummary = response[0].response;
        const ncaaMonthlyRevenue = response[1].response.Result;
        const ncaaNairaLocations = response[2].response.Result;
        const ncaaDollarLocations = response[3].response.Result;
        const ncaaTopCustomers = response[4].response.Result;
        const ncaaTopDollarCustomers = response[5].response.Result;

        // console.log(invoiceSummaryMetrics.Naira.CurrentMonthCount);
        this.setState({
          response: 1,
          monthName,
          year,
          //Naira Invoices
          ncaaInvoicesTodayNairaCount:
            ncaaInvoiceSummaryMetrics.Naira.CurrentDayCount,
          ncaaInvoicesTodayNairaCountPercent:
            ncaaInvoiceSummaryMetrics.Naira.CurrentDayCountPercentageMovement,
          ncaaInvoiceNairaMonthAmount:
            ncaaInvoiceSummaryMetrics.Naira.CurrentMonthAmount,
          ncaaInvoiceNairaMonthAmtPercent:
            ncaaInvoiceSummaryMetrics.Naira
              .CurrentMonthAmountPercentageMovement,
          ncaaCurrentYearNairaInvoices:
            ncaaInvoiceSummaryMetrics.Naira.CurrentYearAmount,
          ncaaCurrentYearNairaInvoicesCountPercent:
            ncaaInvoiceSummaryMetrics.Naira.CurrentYearAmountPercentageMovement,
          //Dollar Invoices
          ncaaInvoicesTodayDollarCount:
            ncaaInvoiceSummaryMetrics.Dollar.CurrentDayCount,
          ncaaInvoicesTodayDollarCountPercent:
            ncaaInvoiceSummaryMetrics.Dollar.CurrentDayCountPercentageMovement,
          ncaaInvoiceDollarMonthAmount:
            ncaaInvoiceSummaryMetrics.Dollar.CurrentMonthAmount,
          ncaaInvoiceDollarMonthAmtPercent:
            ncaaInvoiceSummaryMetrics.Dollar
              .CurrentMonthAmountPercentageMovement,
          ncaaTotalDollarInvoices:
            ncaaInvoiceSummaryMetrics.Dollar.CurrentYearAmount,
          ncaaTotalDollarMonthCountPercent:
            ncaaInvoiceSummaryMetrics.Dollar
              .CurrentYearAmountPercentageMovement,
          location: ncaaInvoiceSummary.Summary.LastInvoice.Location,
          //Naira Monthly Revenue
          ncaaNairaMonthlyRevenueAmount: graphHelpers.seriesData(
            ncaaMonthlyRevenue.Naira.Result,
            "amount"
          ),
          // nairaMonthlyRevenueCount: graphHelpers.chartData(monthlyRevenue.Naira.Result, "count"),
          ncaaNairaMonthlyRevenueCount: graphHelpers.seriesData(
            ncaaMonthlyRevenue.Naira.Result,
            "count"
          ),
          ncaaNairaRevenueMonths: graphHelpers.getCategories(
            ncaaMonthlyRevenue.Naira.Result
          ),
          ncaaNcaaTotalRevenueMonths: graphHelpers.getTotalCategories(
            ncaaMonthlyRevenue.Naira.Result,
            ncaaMonthlyRevenue.Dollar.Result
          ),
          //Dollar Monthly Revenue
          ncaaDollarMonthlyRevenueAmount: graphHelpers.seriesData(
            ncaaMonthlyRevenue.Dollar.Result,
            "amount"
          ),
          ncaaDollarMonthlyRevenueCount: graphHelpers.seriesData(
            ncaaMonthlyRevenue.Dollar.Result,
            "count"
          ),
          ncaaDollarRevenueMonths: graphHelpers.getCategories(
            ncaaMonthlyRevenue.Dollar.Result
          ),
          //Top Locations
          ncaaNairaTopLocationsData: graphHelpers.chartData(
            ncaaNairaLocations,
            "count"
          ),
          ncaaNairaTopLocationsCategory: graphHelpers.chartData(
            ncaaNairaLocations,
            "location"
          ),
          //Dollar Top Locations
          ncaaDollarTopLocationsData: graphHelpers.chartData(
            ncaaDollarLocations,
            "count"
          ),
          ncaaDollarTopLocationsCategory: graphHelpers.chartData(
            ncaaDollarLocations,
            "location"
          ),
          //Naira Top Customers
          nairaTopCustomersData: graphHelpers.chartData(
            ncaaTopCustomers,
            "amount"
          ),
          nairaTopCustomersCategory: graphHelpers.chartData(
            ncaaTopCustomers,
            "customer"
          ),
          //Dollar Top Customers
          ncaaDollarTopCustomersData: graphHelpers.chartData(
            ncaaTopDollarCustomers,
            "amount"
          ),
          ncaaDollarTopCustomersCategory: graphHelpers.chartData(
            ncaaTopDollarCustomers,
            "customer"
          )
        });
        if (
          response[0].status === appConstants.SUCCESS_RESPONSE &&
          response[1].status === appConstants.SUCCESS_RESPONSE &&
          response[2].status === appConstants.SUCCESS_RESPONSE &&
          response[3].status === appConstants.SUCCESS_RESPONSE
        ) {
          this.setState({
            response: 1
          });
        }
      })
      .catch(err => {
        // console.log({ err });
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(err));
        store.addNotification({
          // title: "",
          message: "Unable to process request",
          type: "danger",
          insert: "top",
          width: 250,
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000
            //   onScreen: true
          }
        });
      });

    dispatch(alertActions.startRequest());
  };

  currencySwitch = selected => {
    let { response } = this.state;

    this.setState({
      selectedCurrencyButton: selected,
      getMore: 0
    });

    if (response === 0) {
      this.fetchPageData();
    }
  };

  agencySwitch = selected => {
    let { response } = this.state;
    const { dispatch } = this.props;

    this.setState(
      {
        selectedAgencyButton: selected
      },
      () => {
        if (this.state.selectedAgencyButton === 1) {
          this.fetchPageData();
          if (window.location.pathname !== "/dashboard") {
            dispatch(commonActions.setHeaderTitle("FAAN REVENUE"));
          }
        }
        if (this.state.selectedAgencyButton === 2) {
          this.fetchNCAAPageData();
          if (window.location.pathname !== "/dashboard") {
            dispatch(commonActions.setHeaderTitle("NCAA REVENUE"));
          }
        }
      }
    );

    if (response === 0) {
      this.fetchPageData();
    }
  };

  // changeDemoValue = () => {
  //     let {selectedCurrencyButton} = this.state;
  //     switch (true){
  //         case selectedCurrencyButton === 1:
  //             this.setState({demoValue: 1})
  //             break;
  //         case selectedCurrencyButton === 2:
  //             this.setState({demoValue: 2});
  //             break;
  //         case selectedCurrencyButton === 3:
  //             this.setState({demoValue: 3})
  //             break;
  //         default:
  //             this.setState({demoValue: 4})
  //     }
  // };

  getMore = () => {
    this.setState({ getMore: 1 });
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    let {
      invoicesTodayNairaCount,
      invoicesTodayNairaCountPercent,
      invoiceNairaMonthAmount,
      invoiceNairaMonthAmtPercent,
      currentYearNairaInvoices,
      currentYearNairaInvoicesCountPercent,
      invoicesTodayDollarCount,
      invoicesTodayDollarCountPercent,
      invoiceDollarMonthAmount,
      invoiceDollarMonthAmtPercent,
      totalDollarInvoices,
      totalDollarMonthCountPercent,
      nairaMonthlyRevenueAmount,
      dollarMonthlyRevenueAmount,
      nairaTopLocationsData,
      nairaTopLocationsCategory,
      dollarTopLocationsCategory,
      nairaTopCustomersData,
      dollarTopLocationsData,
      nairaTopCustomersCategory,
      dollarTopCustomersCategory,
      dollarTopCustomersData,
      selectedCurrencyButton,
      getMore,
      monthName,
      year,
      location,
      selectedAgencyButton,
      ncaaInvoicesTodayNairaCount,
      ncaaInvoicesTodayNairaCountPercent,
      ncaaInvoiceNairaMonthAmount,
      ncaaInvoiceNairaMonthAmtPercent,
      ncaaCurrentYearNairaInvoices,
      ncaaCurrentYearNairaInvoicesCountPercent,
      ncaaInvoicesTodayDollarCount,
      ncaaInvoicesTodayDollarCountPercent,
      ncaaInvoiceDollarMonthAmount,
      ncaaInvoiceDollarMonthAmtPercent,
      ncaaTotalDollarInvoices,
      ncaaTotalDollarMonthCountPercent,
      ncaaNairaMonthlyRevenueAmount,
      ncaaDollarMonthlyRevenueAmount,
      ncaaNairaTopLocationsData,
      ncaaNairaTopLocationsCategory,
      ncaaNairaTopCustomersData,
      ncaaNairaTopCustomersCategory,
      ncaaDollarTopCustomersData,
      ncaaDollarTopCustomersCategory,
      ncaaDollarTopLocationsData,
      ncaaDollarTopLocationsCategory
    } = this.state;
    // console.log({nairaMonthlyRevenueAmount});

    return (
      <div>
        {selectedAgencyButton === 1 && (
          <AgencyRevenue
            currencySwitch={this.currencySwitch}
            selectedCurrencyButton={selectedCurrencyButton}
            agencySwitch={this.agencySwitch}
            selectedAgencyButton={selectedAgencyButton}
            getMore={this.getMore}
            getMoreValue={getMore}
            invoicesTodayNairaCount={invoicesTodayNairaCount}
            invoiceNairaMonthAmount={invoiceNairaMonthAmount}
            currentYearNairaInvoices={currentYearNairaInvoices}
            currentYearNairaInvoicesCountPercent={
              currentYearNairaInvoicesCountPercent
            }
            invoicesTodayDollarCount={invoicesTodayDollarCount}
            invoicesTodayDollarCountPercent={invoicesTodayDollarCountPercent}
            invoiceDollarMonthAmount={invoiceDollarMonthAmount}
            invoiceDollarMonthAmtPercent={invoiceDollarMonthAmtPercent}
            totalDollarInvoices={totalDollarInvoices}
            totalDollarMonthCountPercent={totalDollarMonthCountPercent}
            monthName={monthName}
            year={year}
            location={location}
            // totalRevenueMonths={totalRevenueMonths}
            nairaMonthlyRevenueAmount={nairaMonthlyRevenueAmount}
            dollarMonthlyRevenueAmount={dollarMonthlyRevenueAmount}
            nairaTopCustomersCategory={nairaTopCustomersCategory}
            nairaTopCustomersData={nairaTopCustomersData}
            dollarTopCustomersCategory={dollarTopCustomersCategory}
            dollarTopCustomersData={dollarTopCustomersData}
            nairaTopLocationsCategory={nairaTopLocationsCategory}
            nairaTopLocationsData={nairaTopLocationsData}
            invoicesTodayNairaCountPercent={invoicesTodayNairaCountPercent}
            invoiceNairaMonthAmtPercent={invoiceNairaMonthAmtPercent}
            dollarTopLocationsCategory={dollarTopLocationsCategory}
            dollarTopLocationsData={dollarTopLocationsData}
          />
        )}
        {selectedAgencyButton === 2 && (
          <AgencyRevenue
            currencySwitch={this.currencySwitch}
            selectedCurrencyButton={selectedCurrencyButton}
            agencySwitch={this.agencySwitch}
            // setAgencyButtonSelected={this.agencySwitch}
            selectedAgencyButton={selectedAgencyButton}
            getMore={this.getMore}
            getMoreValue={getMore}
            invoicesTodayNairaCount={ncaaInvoicesTodayNairaCount}
            invoiceNairaMonthAmount={ncaaInvoiceNairaMonthAmount}
            currentYearNairaInvoices={ncaaCurrentYearNairaInvoices}
            currentYearNairaInvoicesCountPercent={
              ncaaCurrentYearNairaInvoicesCountPercent
            }
            invoicesTodayDollarCount={ncaaInvoicesTodayDollarCount}
            invoicesTodayDollarCountPercent={
              ncaaInvoicesTodayDollarCountPercent
            }
            invoiceDollarMonthAmount={ncaaInvoiceDollarMonthAmount}
            invoiceDollarMonthAmtPercent={ncaaInvoiceDollarMonthAmtPercent}
            totalDollarInvoices={ncaaTotalDollarInvoices}
            totalDollarMonthCountPercent={ncaaTotalDollarMonthCountPercent}
            monthName={monthName}
            year={year}
            location={location}
            // totalRevenueMonths={totalRevenueMonths}
            nairaMonthlyRevenueAmount={ncaaNairaMonthlyRevenueAmount}
            dollarMonthlyRevenueAmount={ncaaDollarMonthlyRevenueAmount}
            nairaTopCustomersCategory={ncaaNairaTopCustomersCategory}
            nairaTopCustomersData={ncaaNairaTopCustomersData}
            dollarTopCustomersCategory={ncaaDollarTopCustomersCategory}
            dollarTopCustomersData={ncaaDollarTopCustomersData}
            nairaTopLocationsCategory={ncaaNairaTopLocationsCategory}
            nairaTopLocationsData={ncaaNairaTopLocationsData}
            invoicesTodayNairaCountPercent={ncaaInvoicesTodayNairaCountPercent}
            invoiceNairaMonthAmtPercent={ncaaInvoiceNairaMonthAmtPercent}
            dollarTopLocationsCategory={ncaaDollarTopLocationsCategory}
            dollarTopLocationsData={ncaaDollarTopLocationsData}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { requesting, user } = state;
  return {
    requesting,
    user
  };
}

export default connect(mapStateToProps)(Revenue);
