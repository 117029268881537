import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import Chart from "react-apexcharts";
import { AreaChartOptions } from "../../Charts";
import { EmptyRecord } from "../../utilities/EmptyRecord";

export const RevenueChart = ({ monthlyRevenueAmount, sign , ytitle, xtitle}) => {
  return (
    <div>
      <Row>
        <Col md={12}>
          <Card className={"chart-card"}>
            <CardBody className={"card-bg"}>
              <CardTitle>
                <h3>Revenue Trend</h3>
              </CardTitle>
              <div className="chart-wrapper">
                {monthlyRevenueAmount.length > 0 && (
                  <Chart
                    options={AreaChartOptions(["#0032ab"], sign,  ytitle, xtitle)}
                    series={[
                      {
                        name: "Revenue",
                        data: monthlyRevenueAmount
                      }
                    ]}
                    type="area"
                    // width={"100%"}
                    height={"100%"}
                  />
                )}
                {monthlyRevenueAmount.length === 0 && (
                  <EmptyRecord message={"No Record"} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export const TotalRevenueChart = ({
  name1,
  name2,
  nairaRevenue,
  dollarRevenue,
  sign,
                                    ytitle,
    xtitle
}) => {
  return (
    <div>
      <Row>
        <Col md={12}>
          <Card className={"chart-card"}>
            <CardBody className={"card-revenue-bg"}>
              <CardTitle>
                <h3>Revenue Trend</h3>
              </CardTitle>
              <div className="chart-wrapper">
                {/*{*/}
                {/*(nairaRevenue.length === 0 && dollarRevenue.length === 0) &&*/}
                <Chart
                  options={AreaChartOptions(["#0032ab", "#019340"], sign, ytitle, xtitle)}
                  series={[
                    {
                      name: name1,
                      data: nairaRevenue
                    },
                    {
                      name: name2,
                      data: dollarRevenue
                    }
                  ]}
                  type="area"
                  // width={"100%"}
                  height={"100%"}
                />
                {/*}*/}
                {/*{*/}
                {/*(nairaRevenue.length === 0 && dollarRevenue.length === 0) &&*/}
                {/*<EmptyRecord message={"No record"}/>*/}
                {/*}*/}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
