import React, { Component } from "react";
import Iframe from "../../components/iframe";
import { appConstants } from "../../_constants";
import { connect } from "react-redux";
import { commonActions } from "../../_actions";

class LagosInCCTV extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    if(window.location.pathname !== "/dashboard") {
    dispatch(commonActions.setHeaderTitle("LAGOS TOLL - IN"));
    }
  }
  render() {
    return (
      <div>
        <Iframe
          src={appConstants.LAGOS_IN_CCTV_URL}
          width="100%"
          height="100%"
          style={{ border: "0px", overflow: "hidden" }}
          scrolling="no"
        />
      </div>
    );
  }
}

export default connect(null)(LagosInCCTV);
