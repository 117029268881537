import Flights from "../views/flights2/flights2";
import Passengers from "../views/passengers/Passengers";
import Revenue from "../views/revenue/Revenue";
// import Dashboard from "../views/dashboard/Dashboard";
import DashboardSlides from "../views/dashboard/DashboardSlides";
import LagosInCCTV from "../views/cctv/LagosInCCTV";
import LagosOutCCTV from "../views/cctv/LagosOutCCTV";
import AbujaCCTV from "../views/cctv/AbujaCCTV";

var ThemeRoutes = [
  // {
  // 	navlabel: true,
  // 	name: "NAVIGATION",
  // 	// icon: "mdi mdi-dots-horizontal",
  // 	icon: "ti-layout-grid2",
  // },
  {
    path: "/dashboard",
    name: "DASHBOARD",
    icon: "ti-layout-grid2",
    component: DashboardSlides
  },
  {
    path: "/flights",
    name: "FLIGHTS",
    icon: "mdi mdi-airplane",
    component: Flights
  },
  {
    path: "/passengers",
    name: "PASSENGERS",
    icon: "mdi mdi-human-male",
    component: Passengers
  },
  {
    path: "/revenue",
    name: "REVENUE",
    icon: "mdi mdi-cash-multiple",
    component: Revenue
  },
  {
    collapse: true,
    path: "/cctv",
    name: "CCTV",
    icon: "mdi mdi-camera",
    // component: CCTV
    child: [
      {
        path: "/lagos-toll-in",
        name: "LAGOS TOLL - IN",
        // icon: 'mdi mdi-comment-processing-outline',
        component: LagosInCCTV
      },
      {
        path: "/lagos-toll-out",
        name: "LAGOS TOLL - OUT",
        // icon: 'mdi mdi-comment-processing-outline',
        component: LagosOutCCTV
      },
      {
        path: "/abuja-toll",
        name: "ABUJA TOLL",

        // icon: 'mdi mdi-arrange-send-backward',
        component: AbujaCCTV
      }
    ]
  },
  //   { path: "/", pathTo: "/dashboard", name: "DASHBOARD", redirect: true }
  { path: "/", pathTo: "/flights", name: "FLIGHTS", redirect: true }
];
export default ThemeRoutes;
