import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {store} from "../_store";


export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const {user} = store.getState();
        if (!user.access_token) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;