import {Button} from "reactstrap";
import React from "react";

export const Toggle = ({
                                 setButtonSelected,
                                 selectedButton,
                           button1Text,
                           button2Text
                             }) => {

    return (
        <div className={"agency-toggle-btn mb-2"}>
            <Button
                className={"btn-rounded"}
                style={{ borderRadius: "50px 0 0px 50px" }}
                color="primary"
                onClick={() => setButtonSelected(1)}
                active={selectedButton === 1}
            >
                {button1Text}
            </Button>
            <Button
                className={"btn-rounded"}
                style={{ borderRadius: "0 50px 50px 0px" }}
                color="primary"
                onClick={() => setButtonSelected(2)}
                active={selectedButton === 2}
            >
                {button2Text}
            </Button>
        </div>
    );
};