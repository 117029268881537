import React from 'react';
import {Row, Col, Card, CardBody, Badge} from "reactstrap"

export const PassengersCard = ({col, style, title, subTitle, icon, data}) => {
    return (

                <Col md={col}>
                        <Card
                            style={style}
                        >
                            <span className="lstick widget-card bg-white"></span>
                            <CardBody >
                                <div className="d-flex">
                                    <div className="mr-3 align-self-center">
                                        <h4 className={" text-white text-uppercase"}>{title}</h4>
                                        <h6 className="text-white mt-2 mb-0">{subTitle}</h6>
                                        <h1 className="mt-2 mb-0" style={{ color: "rgba(255, 255, 255, 0.5)" }}>{icon}</h1>
                                    </div>
                                    <div className="align-self-center">
                                        {/*<Badge style={{fontSize: "0.9em"}}>{route}</Badge>*/}
                                        <p className="mt-4 mb-0  text-white font-weight-bold" style={{fontSize: "3rem"}}>
                                            {data}
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

    );
};

export const PassengersYearToDataCard = ({col, style, title1, subTitle1, icon1, data1, icon2, data2}) => {
    return (
        <Col md={col}>
            <Card
                style={style}
            >
                <span className="lstick widget-card bg-white"></span>
                <CardBody >
                    <div>
                        <h4 className={" text-white text-uppercase"}>{title1}</h4>
                        <h6 className="text-white mb-0">{subTitle1}</h6>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div className="d-flex">
                                <div className="mr-3 align-self-center">
                                    <h2 className="mt-4 mb-0" style={{ color: "rgba(255, 255, 255, 0.5)" }}>{icon1}</h2>
                                </div>
                                <div className="align-self-center">
                                    <Badge style={{fontSize: "1em"}} className={"mb-0"}>Domestic</Badge>
                                    <p className="mt-0 mb-0 text-white font-weight-bold" style={{fontSize: "2.2rem"}}>
                                        {data1}
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="d-flex">
                                <div className="mr-3 align-self-center">
                                    <h2 className="mt-4 mb-0" style={{ color: "rgba(255, 255, 255, 0.5)" }}>{icon1}</h2>
                                </div>
                                <div className="mr-3 align-self-center">
                                    <Badge style={{fontSize: "1em"}} className={"mb-0"}>International</Badge>
                                    <p className="mt-0 mb-0  text-white font-weight-bold" style={{fontSize: "2.2rem"}}>
                                        {data2}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>

    )
}