import React, { Component } from "react";
import Iframe from "../../components/iframe";
import { appConstants } from "../../_constants";
import { connect } from "react-redux";
// import coatofarms from "../../assets/images/Coat-of-arms-of-Nigeria-01.png";
import { commonActions } from "../../_actions";

class AbujaCCTV extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    if (window.location.pathname !== "/dashboard") {
      dispatch(commonActions.setHeaderTitle("ABUJA TOLL"));
    }
  }
  render() {
    return (
      <div>
        <Iframe
          src={appConstants.ABUJA_CCTV_URL}
          width="100%"
          height="100%"
          style={{ border: "0px", overflow: "hidden" }}
          scrolling="no"
        />
      </div>
      // <div className="">
      //   <div className="auth-box align-items-center">
      //     <div className="error-body text-center mt-5 v-middle">
      //       <img
      //         className="mt-5"
      //         src={coatofarms}
      //         alt="coat-of-arms"
      //         style={{ height: "200px" }}
      //       />
      //       <h3 className="text-dark font-24 font-bold">
      //         Aviation Intelligence Dashboard
      //       </h3>
      //       <div className="mt-4">
      //         <h2>Abuja Toll is coming soon...</h2>
      //       </div>
      //       <div className="mt-5 mb-4">
      //         <i className="ti-settings font-24"></i>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default connect(null)(AbujaCCTV);
