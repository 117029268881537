import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Row,
} from 'reactstrap';
import {alertActions, commonActions} from "../../_actions";
import BottomCard from "../../components/bottomcard/BottomCard";
import FlightsScheduleTable from "./flightsscheduletable";
import {appHelpers} from "../../_helpers";
import {commonService} from "../../_services";
import {appConstants} from "../../_constants";
import {store} from "react-notifications-component";
import moment from "moment";




class FlightMap extends Component {
    constructor(props) {
        super(props);


        this.state = {
            isRequestingSchedule: false,
            flightsScheduleData: [],

        };
    }

    componentDidMount() {
        const {dispatch} = this.props;

        if(window.location.pathname !== "/dashboard") {
            dispatch(commonActions.setHeaderTitle("FLIGHTS"));
        }

        this.fetchFlightsSchedule();
    }

    fetchFlightsSchedule = () => {
        const { dispatch } = this.props;

        let day = moment().format("dddd");
        let dayId = appHelpers.fetchDayId(day);

        this.setState({ isRequestingSchedule: true });
        dispatch(alertActions.startRequest());

        commonService.fetchFlightsSchedule(dayId).then(res => {
            dispatch(alertActions.stopRequest());
            // console.log(res.response.Flights);
            if (res.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({
                    isRequestingSchedule: false,
                    flightsScheduleData: res.response.Flights
                });
            } else if (res.status === appConstants.ERROR_RESPONSE) {
                // dispatch(alertActions.error(res.response));
                store.addNotification({
                    // title: "",
                    message: appHelpers.interpretErrorResponse(res.response),
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000
                        // onScreen: true
                    }
                });
            }
        });
    };


    render() {
        const { isRequestingSchedule,  flightsScheduleData} = this.state;

        return (
            <div className="animated fadeIn">
                <Row>
                    <Col>
                        <Card style={{height: "80vh"}}>
                            <CardBody>
                                {/*<Row>*/}
                                    {/*<Col sm="5">*/}
                                        {/*<CardTitle className="mb-0">Flight Map</CardTitle>*/}
                                        {/*<div className="small text-muted">live view of current flights </div>*/}
                                    {/*</Col>*/}
                                {/*</Row>*/}
                                <div className="chart-wrapper" style={{ marginTop: 10 + 'px', marginLeft: "0"}}>
                                    <iframe style={{ width: "100%", height: "75vh"}}
                                            title="flightmap"
                                            name="flightmap"
                                            src="https://flightaware.com/live/airport_status_bigmap.rvt?airport=DNMM">
                                    </iframe>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <BottomCard
                        title="View Today's Flight Schedules"
                        cardName={"bottom-card"}
                    >
                        <FlightsScheduleTable
                            flightsScheduleData={flightsScheduleData}
                            isRequestingSchedule={isRequestingSchedule}
                        />
                    </BottomCard>
                </Row>


            </div>
        );
    }
}

function mapStateToProps(state) {
    // const { user } = state.auth;
    const { airlines, airports } = state;
    return {
        airlines,
        airports
    };
}

export default connect(mapStateToProps)(FlightMap);
