import React from "react";
import "./index.css";
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import { AgencySwitch, CurrencySwitch } from "./currency-switch";
import InvoiceChart from "./invoice-chart";
import { RevenueChart, TotalRevenueChart } from "./revenue-chart";
import { LocationCustomerChart } from "./location-customer-chart";
import Naira from "./../../assets/images/icons/naira-green(32).png";
import Dollar from "./../../assets/images/icons/dollar-green.png";

export const AgencyRevenue = ({
  currencySwitch,
  selectedCurrencyButton,
  agencySwitch,
  selectedAgencyButton,
  getMoreValue,
  getMore,
  invoicesTodayNairaCount,
  invoiceNairaMonthAmount,
  currentYearNairaInvoices,
  currentYearNairaInvoicesCountPercent,
  monthName,
  year,
  location,
  invoicesTodayDollarCount,
  invoicesTodayDollarCountPercent,
  invoiceDollarMonthAmount,
  invoiceDollarMonthAmtPercent,
  totalDollarInvoices,
  nairaTopCustomersCategory,
  nairaTopCustomersData,
  dollarTopCustomersCategory,
  dollarTopCustomersData,
  nairaTopLocationsCategory,
  nairaTopLocationsData,
  invoicesTodayNairaCountPercent,
  invoiceNairaMonthAmtPercent,
  nairaMonthlyRevenueAmount,
  totalDollarMonthCountPercent,
  dollarRevenueMonths,
  dollarMonthlyRevenueAmount,
  dollarTopLocationsCategory,
  dollarTopLocationsData
}) => {

  return (
    <div>
      <Row>
        <Col md={4}>
          <div style={{ marginBottom: "20px" }}>
            <CurrencySwitch
              setButtonSelected={currencySwitch}
              buttonSelected={selectedCurrencyButton}
            />
          </div>
        </Col>
        <Col md={4} className="text-center">
          <div>
            <AgencySwitch
              setAgencyButtonSelected={agencySwitch}
              selectedAgencyButton={selectedAgencyButton}
            />
          </div>
        </Col>
        <Col md={4}>
          <div>
            <Button
              color="primary"
              className={"btn-more float-right"}
              onClick={getMore}
            >
              <i
                className="fas fa-plus-circle fa-sm pr-2"
                aria-hidden="true"
              ></i>
              More
            </Button>
          </div>
        </Col>
      </Row>
      {selectedCurrencyButton === 1 && getMoreValue === 0 && (
        <div>
          <Row>
            <Col md={6}>
              <InvoiceChart
                title={<img src={Naira} alt={"Naira Icon"} />}
                invoicesTodayCount={invoicesTodayNairaCount}
                invoicesTodayCountPercent={[]}
                invoiceValue={invoiceNairaMonthAmount}
                invoiceMonthPercent={[]}
                invoicesYearToDate={currentYearNairaInvoices}
                totalYearCountPercent={currentYearNairaInvoicesCountPercent}
                monthName={monthName}
                year={year}
                location={location}
              />
            </Col>
            <Col md={6}>
              <InvoiceChart
                title={<img src={Dollar} alt={"Dollar Icon"} />}
                invoicesTodayCount={invoicesTodayDollarCount}
                invoicesTodayCountPercent={invoicesTodayDollarCountPercent}
                invoiceValue={invoiceDollarMonthAmount}
                invoiceMonthPercent={invoiceDollarMonthAmtPercent}
                invoicesYearToDate={totalDollarInvoices}
                totalYearCountPercent={totalDollarMonthCountPercent}
                monthName={monthName}
                year={year}
                location={location}
              />
            </Col>
          </Row>
          <TotalRevenueChart
            name1={"Naira"}
            name2={"Dollar"}
            // totalRevenueMonths={totalRevenueMonths}
            nairaRevenue={nairaMonthlyRevenueAmount}
            dollarRevenue={dollarMonthlyRevenueAmount}
            sign={""}
            ytitle={"Amount"}
            xtitle={"Month"}
          />
        </div>
      )}
      {selectedCurrencyButton === 1 && getMoreValue === 1 && (
        <div>
          <Row>
            <Col md={6}>
              <Card className={"chart-card"}>
                <CardBody className={"card-bg"}>
                  <CardTitle>
                    <h3>
                      Top 5 Paying Customers{" "}
                      <img src={Naira} alt={"Naira Icon"} />
                    </h3>
                  </CardTitle>
                  <LocationCustomerChart
                    cssName={"customer-wrapper"}
                    color={["#0032ab"]}
                    top5Category={nairaTopCustomersCategory}
                    top5Data={nairaTopCustomersData}
                    horizontal={true}
                    title={"Amount"}
                    sign={"&#8358;"}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card className={"chart-card"}>
                <CardBody className={"card-bg"}>
                  <CardTitle>
                    <h3>
                      Top 5 Paying Customers{" "}
                      <img src={Dollar} alt={"Dollar Icon"} />
                    </h3>
                  </CardTitle>
                  <LocationCustomerChart
                    cssName={"customer-wrapper"}
                    color={["#85bb65"]}
                    top5Category={dollarTopCustomersCategory}
                    top5Data={dollarTopCustomersData}
                    horizontal={true}
                    title={"Amount"}
                    sign={"&#36;"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className={"chart-card"}>
                <CardBody className={"card-bg"}>
                  <CardTitle>
                    <h3>
                      Top 5 Locations <img src={Naira} alt={"Naira Icon"} />
                    </h3>{" "}
                  </CardTitle>
                  <LocationCustomerChart
                    cssName={"location-wrapper"}
                    color={["#0032ab"]}
                    top5Category={nairaTopLocationsCategory}
                    top5Data={nairaTopLocationsData}
                    horizontal={false}
                    title={"Location"}
                    ytitle={"Count"}
                    sign={""}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}

      {selectedCurrencyButton === 2 && getMoreValue === 0 && (
        <div>
          <InvoiceChart
            title={<img src={Naira} alt={"Naira Icon"} />}
            invoicesTodayCount={invoicesTodayNairaCount}
            invoicesTodayCountPercent={invoicesTodayNairaCountPercent}
            invoiceValue={invoiceNairaMonthAmount}
            invoiceMonthPercent={invoiceNairaMonthAmtPercent}
            invoicesYearToDate={currentYearNairaInvoices}
            totalYearCountPercent={currentYearNairaInvoicesCountPercent}
            monthName={monthName}
            year={year}
            location={location}
          />
          <RevenueChart
              monthlyRevenueAmount={nairaMonthlyRevenueAmount}
              sign={"&#8358;"}
              ytitle={"Amount"}
              xtitle={"Month"}
          />
        </div>
      )}
      {selectedCurrencyButton === 2 && getMoreValue === 1 && (
        <div>
          <Row>
            <Col md={12}>
              <Card className={"chart-card"}>
                <CardBody className={"card-bg"}>
                  <CardTitle>
                    <h3>
                      Top 5 Paying Customers{" "}
                      <img src={Naira} alt={"Naira Icon"} />
                    </h3>
                  </CardTitle>
                  <LocationCustomerChart
                    cssName={"naira-customer-wrapper"}
                    color={["#0032ab"]}
                    top5Category={nairaTopCustomersCategory}
                    top5Data={nairaTopCustomersData}
                    horizontal={true}
                    title={"Amount"}
                    sign={"&#8358;"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className={"chart-card"}>
                <CardBody className={"card-bg"}>
                  <CardTitle>
                    <h3>
                      Top 5 Locations <img src={Naira} alt={"Naira Icon"} />
                    </h3>
                  </CardTitle>
                  <LocationCustomerChart
                    cssName={"naira-location-wrapper"}
                    color={["#0032ab"]}
                    top5Category={nairaTopLocationsCategory}
                    top5Data={nairaTopLocationsData}
                    horizontal={false}
                    title={"Location"}
                    ytitle={"Count"}
                    sign={""}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {selectedCurrencyButton === 3 && getMoreValue === 0 && (
        <div>
          <InvoiceChart
            title={<img src={Dollar} alt={"Dollar Icon"} />}
            invoicesTodayCount={invoicesTodayDollarCount}
            invoicesTodayCountPercent={invoicesTodayDollarCountPercent}
            invoiceValue={invoiceDollarMonthAmount}
            invoiceMonthPercent={invoiceDollarMonthAmtPercent}
            invoicesYearToDate={totalDollarInvoices}
            totalYearCountPercent={totalDollarMonthCountPercent}
            monthName={monthName}
            year={year}
            location={location}
          />
          <RevenueChart
            revenueMonths={dollarRevenueMonths}
            monthlyRevenueAmount={dollarMonthlyRevenueAmount}
            sign={"&#36;"}
            ytitle={"Amount"}
            xtitle={"Month"}

          />
        </div>
      )}
      {selectedCurrencyButton === 3 && getMoreValue === 1 && (
        <div>
          <Row>
            <Col md={12}>
              <Card className={"chart-card"}>
                <CardBody className={"card-bg"}>
                  <CardTitle>
                    <h3>
                      Top 5 Paying Customers{" "}
                      <img src={Dollar} alt={"Dollar Icon"} />
                    </h3>
                  </CardTitle>
                  <LocationCustomerChart
                    cssName={"dollar-location-wrapper"}
                    color={["#85bb65"]}
                    top5Category={dollarTopCustomersCategory}
                    top5Data={dollarTopCustomersData}
                    horizontal={true}
                    title={"Amount"}
                    sign={"&#36;"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card className={"chart-card"}>
                <CardBody className={"card-bg"}>
                  <CardTitle>
                    <h3>
                      Top 5 Locations <img src={Dollar} alt={"Dollar Icon"} />
                    </h3>
                  </CardTitle>
                  <LocationCustomerChart
                    cssName={"dollar-customer-wrapper"}
                    color={["#0032ab"]}
                    top5Category={dollarTopLocationsCategory}
                    top5Data={dollarTopLocationsData}
                    horizontal={false}
                    title={"Location"}
                    sign={""}
                    // ytitle={"Count"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AgencyRevenue;
