import React from "react";
import Chart from "react-apexcharts";
import { BarChartOptions } from "../../Charts";
import {EmptyRecord} from "../../utilities/EmptyRecord";

export const LocationCustomerChart = ({cssName, top5Category, top5Data , horizontal, color, title, ytitle, sign}) => {
    return (
        <div>
            <div className={`${cssName} `}>
                {top5Data.length > 0 &&
                <Chart
                    options={BarChartOptions(color,['#745af2', '#263238'], top5Category, horizontal, title, ytitle, sign)}
                    series={ [{
                        name: "",
                        data: top5Data
                    }]}
                    type="bar"
                    height="100%"
                />
                }
                {
                    top5Data.length === 0 &&
                        <EmptyRecord message={"No Record"}/>
                }
            </div>
        </div>
    )
};

// export const TotalCustomerChart = ({name1, nairaRevenue, name2, dollarRevenue}) => {
//     return (
//         <div>
//             <div className={"chart-wrapper"}>
//                 <Chart
//                     options={TotalBarChart()}
//                     series={  [{
//                         name: 'Desktop',
//                         data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
//                     }, {
//                         name: 'Mobile',
//                         data: [76, 85, 101, 98]
//                     }]}
//                     type="bar"
//                     height="300"
//                 />
//             </div>
//         </div>
//     )
// }