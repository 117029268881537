import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Form,
  Row,
  Col} from "reactstrap";
import loginbg from "../../assets/images/background/aircraftbg.jpg";
import { connect } from "react-redux";
import { store } from 'react-notifications-component';


import coatofarms from "../../assets/images/Coat-of-arms-of-Nigeria-01.png";
// import aviationdashboardlogo from "../../assets/images/aviation-dashboard-logo-home.png";
import { commonActions, alertActions } from "../../_actions";
import { appConstants } from "../../_constants";
import { commonService } from "../../_services";
import { history } from "../../jwt/_helpers";

const sidebarBackground = {
  backgroundImage: "url(" + loginbg + ")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center"
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    // this.handleClick = this.handleClick.bind(this);

    // reset login status
    this.props.dispatch(commonActions.logOut());
    this.props.dispatch(commonActions.clearAirports());
    this.props.dispatch(commonActions.clearAirlines());

    this.state = {
      isRequesting: false,
      username: "",
      password: "",
      error: false,
      componentError: false
    };
  }

  componentDidMount() {}

  componentWillReceiveProps = function(nextProps) {
    const { alert } = nextProps;
    if (alert) {
      if (alert.type === appConstants.SUCCESS) {
        this.setState({ error: false, success: alert.message });
      } else if (alert.type === appConstants.CLEAR) {
        this.setState({ success: false, error: false });
      } else {
        this.setState({ success: false, error: alert.message });
      }
    }
  };

  handleChange = (event, attribute) => {
    let currState = this.state;
    currState[attribute] = event.target.value;
    this.setState(currState);
  };

  validateInput = () => {
    if (this.state.username === "") {
      this.setState({ componentError: "Please enter username" });
    } else if (this.state.password === "") {
      this.setState({ componentError: "Please enter password" });
    } else {
      this.setState({ componentError: false });
      return true;
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    this.resetError();
    if (this.validateInput()) {
      // console.log('Submitting...');
      this.setState({ isRequesting: true });
      this.processSubmit();
    }
  };

  processSubmit = () => {
    const { username, password } = this.state;
    const { dispatch } = this.props;
    let payload = JSON.stringify({ Username: username, Password: password });
    // dispatch(commonActions.login(payload));
    //   .then(this.setState({ isRequesting: false }))
    //   .catch(this.setState({ isRequesting: false }));
    commonService.login(payload).then(
      response => {
        this.setState({isRequesting: false})
        if (response.status === appConstants.SUCCESS_RESPONSE) {
          let user = response.response;
          // user.AppRole = appHelpers.interpretRole(user);
          dispatch(success(user));
          // dispatch(alertActions.stopRequest());
          // dispatch(alertActions.clear());
          //redirect user

          history.push("/flights");
          // history.push("/dashboard");

          // userHomepage(response.response); //redirect user to homepage
        } else {
          store.addNotification({
            // title: "",
            message: response.response,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              // onScreen: true
            }
          });
          // dispatch(failure(response.response));
          // dispatch(alertActions.stopRequest());
        }
      },
      error => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
        this.setState({isRequesting: false})
        // dispatch(alertActions.stopRequest());
      }
    );

    function success(user) {
      return { type: appConstants.SIGN_IN_SUCCESS, user };
    }
    function failure(message) {
      return { type: appConstants.ERROR, message };
    }
  };

  resetError() {
    this.props.dispatch(alertActions.clear());
  }

  renderPageResponse() {
    if (this.state.error) {
      return (
        <div className="alert alert-danger text-capitalize-first">
          {this.state.error}
        </div>
      );
    } else if (this.state.componentError) {
      return (
        <div className="alert alert-danger text-capitalize-first">
          {this.state.componentError}
        </div>
      );
    } else if (this.state.success) {
      return (
        <div className="alert alert-success text-capitalize-first">
          {this.state.success}
        </div>
      );
    }
  }

  //   handleClick() {
  //     var elem = document.getElementById("loginform");
  //     elem.style.transition = "all 2s ease-in-out";
  //     elem.style.display = "none";
  //     document.getElementById("recoverform").style.display = "block";
  //   }

  render() {
    let pageResponse = this.renderPageResponse();
    const { isRequesting } = this.state;

    return (
      <div className="">
        {/*--------------------------------------------------------------------------------*/}
        {/*Login Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div
          className="auth-wrapper d-flex no-block justify-content-center align-items-center"
          style={sidebarBackground}
        >
          <div className="auth-box">
            <div id="loginform">
              <div className="logo">
                <span className="db">
                  <img
                    src={coatofarms}
                    style={{ maxHeight: "65px" }}
                    alt="logo"
                  />
                 
                </span>
                {/* <div className=" mb-3 text-center">
                <img
                    src={aviationdashboardlogo}
                    style={{ height: "30px" }}
                    alt="logo"
                  />
                </div> */}
                <h3 className="font-medium mb-3">Aviation Intelligence Dashboard</h3>
                <p className="font-bold">Sign in to continue</p>
              </div>
              <Row>
                <Col xs="12">
                  {/* <Formik
				                    initialValues={{
				                        username: '',
				                        password: ''
				                    }}
				                    validationSchema={Yup.object().shape({
				                        username: Yup.string().required('Email address is required'),
				                        password: Yup.string().required('Password is required')
				                    })}
				                    onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
										setStatus();
										// this.handleSubmit(username, password)
										// dispatch(commonActions.postSignIn(payload));
				                        authenticationService.login(username, password)
				                            .then(
				                                user => {
				                                    const { from } = this.props.location.state || { from: { pathname: "/" } };
				                                    this.props.history.push(from);
				                                },
				                                error => {
				                                    setSubmitting(false);
				                                    setStatus(error);
				                                }
				                            );
				                    }}
									render={({ errors, status, touched, isSubmitting }) => ( */}
                  {/* {status &&
								<div className={'alert alert-danger'}>{status}</div>} */}
                  {pageResponse}
                  <Form
                    className="mt-3"
                    id="loginform"
                    onSubmit={this.handleSubmit}
                  >
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ti-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="email"
                        placeholder=""
                        value={this.state.username}
                        onChange={event => this.handleChange(event, "username")}
                      />

                      {/* <Field
                        name="username"
                        type="text"
                        className={"form-control"}
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="invalid-feedback"
                      /> */}
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ti-pencil"></i>
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        type="password"
                        placeholder=""
                        value={this.state.password}
                        onChange={event => this.handleChange(event, "password")}
                      />
                      {/* <Field
                        name="password"
                        type="password"
                        className={"form-control"}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      /> */}
                    </InputGroup>
                    {/* <div className="d-flex no-block align-items-center mb-3">
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomCheckbox"
                        label="Remember Me"
                      />
                      <div className="ml-auto">
                        <a
                          href="#recoverform"
                          id="to-recover"
                          onClick={this.handleClick}
                          className="forgot text-dark float-right"
                        >
                          <i className="fa fa-lock mr-1"></i> Forgot pwd?
                        </a>
                      </div>
                    </div> */}
                    <Row className="mb-3">
                      <Col xs="12">
                        <button
                          type="submit"
                          className="btn btn-block btn-success font-bold"
                          disabled={isRequesting}
                        >
                          {isRequesting ? (
                            <span>
                              Signing you in...  <i
                                className="fa fa-spinner fa-spin"
                              ></i>
                            </span>
                          ) : (
                            `Sign in`
                          )}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                  {/* )} */}
                  {/* /> */}
                </Col>
              </Row>
            </div>
            {/* <div id="recoverform">
						<div className="logo">
							<span className="db"><img src={img1} alt="logo" /></span>
							<h5 className="font-medium mb-3">Recover Password</h5>
							<span>Enter your Email and instructions will be sent to you!</span>
						</div>
						<Row className="mt-3">
							<Col xs="12">
								<Form action="/dashbaord">
									<FormGroup>
										<Input type="text" name="uname" bsSize="lg" id="Name" placeholder="Username" required />
									</FormGroup>
									<Row className="mt-3">
										<Col xs="12">
											<Button color="danger" size="lg" type="submit" block>Reset</Button>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</div> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, user, requesting } = state;
  return {
    alert,
    user,
    requesting
  };
}

export default connect(mapStateToProps)(Login);
