import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function headerTitle(state = initialState.headerTitle, action) {

  switch (action.type) {
    case appConstants.SET_HEADER_TITLE:
      return action.title;
    default:
      return state;
  }
}
