import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function airports(state = initialState.airports, action) {
  switch (action.type) {
    case appConstants.AIRPORTS_SUCCESS:
      return action.airports;
    case appConstants.CLEAR_AIRPORTS:
      return [];
    default:
      return state;
  }
}
