import React, { Component } from "react";

import { Card, CardBody, Badge } from "reactstrap";

import "./index.css";

class BottomCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCard: false
    };
  }

  toggleCard = () => {
    this.setState({ showCard: !this.state.showCard });
  };

  render() {
    const { children, title, cardName, route } = this.props;
    return (
      <div
        className={`${cardName} ${
          this.state.showCard ? `show-bottom-card` : ``
        }`}
        id="bottom-card"
      >
        <Card className="shadow rounded mb-0">
          <span className="lstick bg-white"></span>
          <div
            className="bg-secondary stats-bar"
            onClick={this.toggleCard}
            style={{ cursor: "pointer" }}
          >
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="p-3 w-100 text-truncate">
                  <h4 className="d-inline text-uppercase text-white">
                    <i className="mdi mdi-format-list-bulleted mr-2"> </i>
                    {title}
                    <Badge className={"ml-2"} color={"primary"}>{route}</Badge>
                    <span
                    className="pull-right clickable close-icon"
                    data-effect="fadeOut"
                  >
                    {this.state.showCard ? (
                      <i className="fa fa-arrow-circle-down"></i>
                    ) : (
                      <i className="fa fa-arrow-circle-up"></i>
                    )}</span>
                  </h4>
                
                  
                </div>
              </div>
            </div>
          </div>
          <CardBody>{children}</CardBody>
        </Card>
      </div>
    );
  }
}

export default BottomCard;
