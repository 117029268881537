import { alertConstants, appConstants } from "../_constants";
import { commonService } from "../_services";
import { history } from "../_helpers";
import { alertActions } from "./alert.actions";
// import { alertActions } from '../_actions';

export const commonActions = {
  login,
  logOut,
  getAllAirlines,
  getAllAirports,
  clearAirports,
  clearAirlines,
  setHeaderTitle
};

function login(payload) {
  return dispatch => {
    // dispatch(alertActions.startRequest());
    return commonService.login(payload).then(
      response => {
        if (response.status === appConstants.SUCCESS_RESPONSE) {
          let user = response.response;
          // user.AppRole = appHelpers.interpretRole(user);
          dispatch(success(user));
          // dispatch(alertActions.stopRequest());
          // dispatch(alertActions.clear());
          //redirect user

          // history.push("/dashboard");
          history.push("/flights");

          // userHomepage(response.response); //redirect user to homepage
        } else {
          dispatch(failure(response.response));
          dispatch(alertActions.stopRequest());
        }
      },
      error => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
        dispatch(alertActions.stopRequest());
      }
    );
  };
  function success(user) {
    return { type: appConstants.SIGN_IN_SUCCESS, user };
  }
  function failure(message) {
    return { type: alertConstants.ERROR, message };
  }
}

function logOut() {
  commonService.logOut();
  return { type: appConstants.SIGN_OUT };
  // return dispatch => {
  //     // dispatch(success({}));
  //     function success(user) {
  //         return { type: appConstants.SIGN_OUT, user }
  //     }
  // }
}

function getAllAirlines() {
  return dispatch => {
    return commonService.fetchAllAirlines().then(
      response => {
        if (response.error == null) {
          dispatch(success(response.response));
        } else {
          dispatch(failure(response));
        }
      },
      error => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
      }
    );
  };

  function success(airlines) {
    return { type: appConstants.AIRLINES_SUCCESS, airlines };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getAllAirports() {
  return dispatch => {
    return commonService.fetchAllAirports().then(
      response => {
        if (response.error == null) {
          let filteredData = response.response.Results.filter(
            item => item.Latitude !== null && item.Longitude !== null
          ).map(item => {
            item.pos_latitude = parseFloat(item.Latitude);
            item.pos_longitude = parseFloat(item.Longitude);

            return item;
          });
          dispatch(success(filteredData));
        } else {
          dispatch(failure(response));
        }
      },
      error => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
      }
    );
  };

  function success(airports) {
    return { type: appConstants.AIRPORTS_SUCCESS, airports };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function clearAirports() {
  return { type: appConstants.CLEAR_AIRPORTS };
}

function clearAirlines() {
  return { type: appConstants.CLEAR_AIRLINES };
}

function setHeaderTitle(title) {
  return { type: appConstants.SET_HEADER_TITLE, title };
}
