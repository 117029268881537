import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/style.scss';
// setup fake backend
// import { configureFakeBackend } from './jwt/_helpers';
import App from './app';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// configureFakeBackend();

// const App = require('./app').default;

ReactDOM.render( 
	<App /> , document.getElementById('root')
);