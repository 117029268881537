import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// am4 charts imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_region_nigeriaLow from "@amcharts/amcharts4-geodata/nigeriaLow";

import AirportMarker from "../../../assets/images/airport-marker.png";

am4core.useTheme(am4themes_animated);

class FlightsMap extends Component {
  static propTypes = {
    chartId: PropTypes.string.isRequired,
    flightsData: PropTypes.array.isRequired,
    airportsData: PropTypes.array.isRequired
  };
  state = {
    intervalId: null
  };

  componentDidMount() {
    // initialize map
    this.initChart();

    // update flight position after specified interval
    let intervalId = setInterval(() => this.updateFlightPosition(), 5000);
    this.setState({ intervalId: intervalId });
  }

  componentDidUpdate(prevProps) {
    //Handle refreshing the chart when the dataset changes

    if (!_.isEqual(prevProps.airportsData, this.props.airportsData)) {
      this.airportSeries.data = [...this.props.airportsData];
    }
    // this.updateFlightPosition();
  }

  updateFlightPosition = () => {
    const { flightsData } = this.props;

    let lineSeries = this.lineSeries;

    lineSeries.invalidateData();
    lineSeries.mapLines.clear();

    lineSeries.data = [...flightsData];

    const handleMarkerClick = this.props.handleMarkerClick;

    lineSeries.data.forEach(function(item) {
      let line = lineSeries.mapLines.create();
      line.multiGeoLine = item.multiGeoLine;
      line.invalidate();

      // Add a map object to line
      let bullet = line.lineObjects.create();
      bullet.nonScaling = true;
      bullet.position = 1;
      bullet.width = 20;
      bullet.height = 20;
      bullet.horizontalCenter = "middle";
      bullet.verticalCenter = "middle";

      let plane = bullet.createChild(am4core.Image);
      plane.scale = 0.08;
      plane.path =
        "m2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47";
      plane.fill = am4core.color("#192a56");
      plane.strokeOpacity = 0;

      // // Add event on marker on hover
      plane.events.on("hit", function(ev) {
        handleMarkerClick(item);
      });
    });
  };

  componentWillUnmount() {
    if (this.chart._super) {
      this.chart.dispose();
    }

    clearInterval(this.state.intervalId);
  }

  // initialise chart
  initChart = () => {
    const { chartId, airportsData } = this.props;

    let chart = am4core.create(chartId, am4maps.MapChart);
    this.chart = chart;

    // Set map definition
    chart.geodata = am4geodata_region_nigeriaLow;
    chart.hideCredits = true;
    // Set projection
    chart.projection = new am4maps.projections.Miller();

    chart.paddingTop = 10;
    chart.paddingLeft = 10;
    chart.paddingRight = 10;
    chart.paddingBottom = 10;

    // Add zoom control
    chart.zoomControl = new am4maps.ZoomControl();

    // Set initial zoom level
    chart.homeZoomLevel = 1;

    // Add background color
    chart.background.fill = am4core.color("#F4F6F9");
    chart.background.fillOpacity = 1;

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Add a drop shadow filter on columns
    let shadow = polygonSeries.filters.push(new am4core.DropShadowFilter());
    shadow.dx = 1;
    shadow.dy = 1;
    shadow.blur = 5;
    shadow.opacity = 0.5;

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;

    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#74B266");

    // add airport series
    let airportSeries = chart.series.push(new am4maps.MapImageSeries());
    let airportSeriesTemplate = airportSeries.mapImages.template;
    this.airportSeries = airportSeries;

    // set up airport marker
    let airportMarker = airportSeriesTemplate.createChild(am4core.Image);
    airportMarker.href = `${AirportMarker}`;
    airportMarker.width = 12;
    airportMarker.height = 12;
    airportMarker.nonScaling = true;
    airportMarker.tooltipText = "{Name}";
    airportMarker.horizontalCenter = "middle";
    airportMarker.verticalCenter = "bottom";

    // set airport series template property fields
    airportSeriesTemplate.propertyFields.latitude = "pos_latitude";
    airportSeriesTemplate.propertyFields.longitude = "pos_longitude";

    airportSeries.data = [...airportsData];

    // Add line series
    let lineSeries = chart.series.push(new am4maps.MapLineSeries());
    lineSeries.mapLines.template.strokeWidth = 4;
    lineSeries.mapLines.template.shortestDistance = false;
    lineSeries.mapLines.template.stroke = am4core.color("rgba(0, 0, 0, 0)");

    this.lineSeries = lineSeries;

    this.updateFlightPosition();
  };

  render() {
    return <div className="flight-map" id={this.props.chartId}></div>;
  }
}

export default FlightsMap;
